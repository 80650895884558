import React from 'react';
import config from '../config';

export default function PageHero(props) {
  if (props.title !== config.appName) {
    document.title = `${props.title} | ${config.appName}`;
  } else {
    document.title = config.appName;
  }

  return (
    <div className="container">
      <h2 className="page-title">{props.title}</h2>
    </div>
  );
}
