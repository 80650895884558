import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Message from '../../components/Message';
import useToken from '../../hooks/useToken';
import { fetchWithToken } from '../../fetcher';

export default function PurchaseOrderForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [selectedFile, setSelectedFile] = useState();
  let { hasPermission } = useToken();

  const [truck, setTruck] = useState();
  const [selectedNewVendor, setSelectedNewVendor] = useState(false);

  useEffect(() => {
    let thisTruck = props.trucks.filter((x) => x.id == truck)[0];

    if (thisTruck) {
      formik.setFieldValue('station', thisTruck.station.id);
    }
  }, [truck]);

  useEffect(() => {
    // If we just created a new Vendor, automatically select it!
    if (selectedNewVendor) {
      let thisVendor = props.vendors.filter(
        (x) => x.name === selectedNewVendor
      )[0];

      formik.setFieldValue('vendor', thisVendor.id);

      setSelectedNewVendor(null);
    }
  }, [props.vendors]);

  let validationSchema = yup.object({
    description: yup.string().required('Description must be provided'),
    truck: yup
      .number('Truck must be selected')
      .required('Truck must be selected'),
    station: yup
      .number('Station must be selected')
      .required('Station must be selected'),
    vendor: yup
      .number('Vendor must be selected')
      .required('Vendor must be selected'),
    estimatedAmount: yup
      .number('Estimated Amount is required')
      .required('Estimated Amount is required')
      .typeError('Estimated Amount must be a number'),
  });

  let initialValues = {
    description: '',
    truck: '',
    station: '',
    vendor: '',
    estimatedAmount: '',
  };

  console.dir(props.initialValues);

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      if (props.initialValues.truck) {
        initialValues.truck = props.initialValues.truck.id;
      }

      if (props.initialValues.station) {
        initialValues.station = props.initialValues.station.id;
      }

      if (props.initialValues.vendor) {
        initialValues.vendor = props.initialValues.vendor.id;
      }

      if (props.initialValues.estimated_amount) {
        initialValues.estimatedAmount = props.initialValues.estimated_amount;
      }

      if (props.initialValues.description) {
        initialValues.description = props.initialValues.description;
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let imageId = null;

      if (selectedFile !== undefined) {
        const formData = new FormData();

        formData.append('file', selectedFile);

        let response = await fetchWithToken('/upload', {
          method: 'POST',
          body: formData,
        });

        imageId = response.id;
      }

      setSubmitting(true);

      if (props.isAdding) {
        props.handleSubmit(values, imageId);
      } else {
        props.handleSubmit(props.initialValues, values);
      }
    },
  });

  const handleDelete = (id) => {
    setSubmitting(true);
    props.handleDelete(id);
  };

  const handleApprovePurchaseOrder = (id) => {
    setSubmitting(true);
    props.handleApprovePurchaseOrder(id);
  };

  const handleClosePurchaseOrder = (id) => {
    setSubmitting(true);
    props.handleClosePurchaseOrder(id);
  };

  const handleDeclinePurchaseOrder = (id) => {
    setSubmitting(true);
    props.handleDeclinePurchaseOrder(id);
  };

  const { getFieldProps } = formik;

  let enabledTrucks = props.trucks
    .filter((x) => x.enabled)
    .sort((a, b) => {
      return a.truck_number - b.truck_number;
    })
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.truck_number}
        </option>
      );
    });

  let enabledStations = props.stations
    .filter((x) => x.enabled)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.name}
        </option>
      );
    });

  let enabledVendors = props.vendors
    .filter((x) => x.enabled)
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    })
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.name}
        </option>
      );
    });

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the Purchase Order is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the Purchase Order is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  const handleTruckChange = (e) => {
    if (e) {
      setTruck(e.target.value);

      formik.handleChange(e);
    }
  };

  const handleNewVendor = (e) => {
    e.preventDefault();

    let newVendorName = prompt('Please enter the name of the new Vendor');

    if (newVendorName !== null) {
      props.handleCreateVendor(newVendorName);
      setSelectedNewVendor(newVendorName);
    }
  };

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* Status */}
          <div className="form-group col-md-12 mb-3">
            <label className="form-label">Status</label>

            {!props.isAdding && props.initialValues.status.name}
            {props.isAdding && 'New'}
          </div>

          {/* Truck */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Truck</label>

            <select
              {...getFieldProps('truck')}
              onChange={handleTruckChange}
              className={`form-select ${
                formik.errors.truck && formik.touched.truck ? 'is-invalid' : ''
              }`}
              type="text"
            >
              <option value="">Choose a Truck</option>

              {/* Pull in all other trucks */}
              {enabledTrucks}
            </select>

            {formik.errors.truck && formik.touched.truck ? (
              <span style={{ color: '#D53939' }}>{formik.errors.truck}</span>
            ) : null}
          </div>

          {/* Station */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Station</label>

            <select
              {...getFieldProps('station')}
              className={`form-select ${
                formik.errors.station && formik.touched.station
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
            >
              <option value="">Choose a Station</option>

              {/* Pull in all other stations */}
              {enabledStations}
            </select>

            {formik.errors.station && formik.touched.station ? (
              <span style={{ color: '#D53939' }}>{formik.errors.station}</span>
            ) : null}
          </div>

          {/* Vendor */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Vendor</label>

            <div className="input-group input-group-flat">
              <select
                {...getFieldProps('vendor')}
                className={`form-select ${
                  formik.errors.vendor && formik.touched.vendor
                    ? 'is-invalid'
                    : ''
                }`}
                type="text"
              >
                <option value="">Choose a Vendor</option>

                {enabledVendors}
              </select>
              <a
                href="#"
                className="input-group-link btn btn-sm btn-primary"
                onClick={handleNewVendor}
              >
                New
              </a>
            </div>

            {formik.errors.vendor && formik.touched.vendor ? (
              <span style={{ color: '#D53939' }}>{formik.errors.vendor}</span>
            ) : null}
          </div>

          {/* Estimated Amount */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Estimated Amount</label>

            <input
              {...getFieldProps('estimatedAmount')}
              className={`form-control ${
                formik.errors.estimatedAmount && formik.touched.estimatedAmount
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
              placeholder="Enter Estimated Amount"
            />

            {formik.errors.estimatedAmount && formik.touched.estimatedAmount ? (
              <span style={{ color: '#D53939' }}>
                {formik.errors.estimatedAmount}
              </span>
            ) : null}
          </div>

          {/* Description */}
          <div className="form-group col-md-12 mb-3">
            <label className="form-label">Description</label>

            <textarea
              {...getFieldProps('description')}
              className={`form-control ${
                formik.errors.description && formik.touched.description
                  ? 'is-invalid'
                  : ''
              }`}
              rows="6"
              placeholder="Add description"
            />

            {formik.errors.description && formik.touched.description ? (
              <span style={{ color: '#D53939' }}>
                {formik.errors.description}
              </span>
            ) : null}
          </div>

          {/* Image Chooser */}

          {props.isAdding && (
            <div className="form-group col-md-12 mb-3">
              <input type="file" onChange={onFileChange} />
            </div>
          )}
        </div>

        {/* Approve */}
        {!props.isAdding && hasPermission('approvepo') && (
          <button
            className="btn btn-success"
            style={{ marginTop: '1em', marginRight: '1em' }}
            onClick={() => handleApprovePurchaseOrder(props.purchaseOrderId)}
            type="button"
          >
            Approve
          </button>
        )}

        {/* Decline */}
        {!props.isAdding && hasPermission('approvepo') && (
          <button
            className="btn btn-warning"
            style={{ marginRight: '1em', marginTop: '1em' }}
            onClick={() => handleDeclinePurchaseOrder(props.purchaseOrderId)}
            type="button"
          >
            Decline
          </button>
        )}

        {!props.isAdding && hasPermission('approvepo') && (
          <button
            className="btn btn-yellow"
            style={{ marginTop: '1em', marginRight: '1em' }}
            onClick={() => handleClosePurchaseOrder(props.purchaseOrderId)}
            type="button"
          >
            Close
          </button>
        )}

        {/* Submit Button */}
        <button
          className="btn btn-primary"
          style={{ marginRight: '1em', marginTop: '1em' }}
          type="submit"
        >
          Submit
        </button>

        {/* Delete Button */}
        {!props.isAdding && (
          <button
            className="btn btn-danger"
            style={{ marginTop: '1em', marginRight: '1em' }}
            onClick={() => handleDelete(props.purchaseOrderId)}
            type="button"
          >
            Delete
          </button>
        )}
      </form>
    </>
  );
}
