import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Message from '../../components/Message';

export default function TruckForm(props) {
  let [submitting, setSubmitting] = useState(false);

  let initialValues = {
    truckNumber: '',
    truckState: '',
    truckEntity: '',
    truckStation: '',
    excludeFromSettlement: false,
    isThisTruckActive: true,
    notes: ''
  };

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      initialValues.truckNumber = props.initialValues.truck_number;
      initialValues.truckState = props.initialValues.state.id;
      initialValues.truckEntity = props.initialValues.entity.id;
      initialValues.truckStation = props.initialValues.station.id;
      initialValues.excludeFromSettlement =
        props.initialValues.exclude_from_settlement;
      initialValues.isThisTruckActive = props.initialValues.enabled;
      initialValues.notes = props.initialValues.notes;
    }
  }

  let validationSchema = yup.object({
    truckNumber: yup
      .number()
      .typeError('Truck Number must be a number')
      .required('Truck Number is required'),
    truckState: yup
      .number('Primary State must be selected')
      .required('Primary State must be selected'),
    truckEntity: yup
      .number('Entity must be selected')
      .required('Entity must be selected'),
    truckStation: yup
      .number('Station must be selected')
      .required('Station must be selected'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      props.handleSubmit(values);
    },
  });

  const { getFieldProps } = formik;

  let enabledStates = props.states
    .filter((x) => x.enabled)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.name}
        </option>
      );
    });

  let enabledEntities = props.entities
    .filter((x) => x.enabled)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.name}
        </option>
      );
    });

  let enabledStations = props.stations
    .filter((x) => x.enabled)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.name}
        </option>
      );
    });

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the Truck is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the Truck is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        {/* Truck Number */}
        <div className="form-group col-md-6 mb-3">
          <label className="form-label">Truck Number</label>

          <input
            {...getFieldProps('truckNumber')}
            className={`form-control ${
              formik.errors.truckNumber && formik.touched.truckNumber
                ? 'is-invalid'
                : ''
            }`}
            type="text"
            placeholder="Enter Truck Number"
            disabled={!props.isAdding}
            autoFocus
          />

          {formik.errors.truckNumber && formik.touched.truckNumber ? (
            <span style={{ color: '#D53939' }}>
              {formik.errors.truckNumber}
            </span>
          ) : null}
        </div>

        {/* Truck State */}
        <div className="form-group col-md-6 mb-3">
          <label className="form-label">Primary State</label>

          <select
            {...getFieldProps('truckState')}
            className={`form-select ${
              formik.errors.truckState && formik.touched.truckState
                ? 'is-invalid'
                : ''
            }`}
            type="text"
            placeholder="Enter Truck Number"
          >
            <option value="">Choose a State</option>

            {/* Pull in all other states */}
            {enabledStates}
          </select>

          {formik.errors.truckState && formik.touched.truckState ? (
            <span style={{ color: '#D53939' }}>{formik.errors.truckState}</span>
          ) : null}
        </div>

        {/* Truck Entity */}
        <div className="form-group col-md-6 mb-3">
          <label className="form-label">Entity</label>

          <select
            {...getFieldProps('truckEntity')}
            className={`form-select ${
              formik.errors.truckEntity && formik.touched.truckEntity
                ? 'is-invalid'
                : ''
            }`}
            type="text"
          >
            <option value="">Choose an Entity</option>

            {/* Pull in all entities */}
            {enabledEntities}
          </select>

          {formik.errors.truckEntity && formik.touched.truckEntity ? (
            <span style={{ color: '#D53939' }}>
              {formik.errors.truckEntity}
            </span>
          ) : null}
        </div>

        {/* Truck Station */}
        <div className="form-group col-md-6 mb-3">
          <label className="form-label">Station</label>

          <select
            {...getFieldProps('truckStation')}
            className={`form-select ${
              formik.errors.truckStation && formik.touched.truckStation
                ? 'is-invalid'
                : ''
            }`}
            type="text"
          >
            <option value="">Choose a Station</option>

            {/* Pull in all stations */}
            {enabledStations}
          </select>

          {formik.errors.truckStation && formik.touched.truckStation ? (
            <span style={{ color: '#D53939' }}>
              {formik.errors.truckStation}
            </span>
          ) : null}
        </div>
      </div>

      {/* Options */}
      <div className="form-group col-md-12 mb-3">
        <label className="form-label">Options</label>

        {/* Exclude From Settlement? */}
        <label className="form-check mb-2">
          <input
            {...getFieldProps('excludeFromSettlement')}
            className="form-check-input"
            type="checkbox"
            defaultChecked={initialValues.excludeFromSettlement}
          />
          <span className="form-check-label">Exclude From Settlement?</span>
        </label>

        {/* Is This Truck Active? */}
        <label className="form-check mb-2">
          <input
            {...getFieldProps('isThisTruckActive')}
            className="form-check-input"
            type="checkbox"
            defaultChecked={initialValues.isThisTruckActive}
          />
          <span className="form-check-label">Is This Truck Active?</span>
        </label>
      </div>

      {/* Notes */}
      <div className="form-group col-md-12 mb-3">
          <label className="form-label">Notes</label>

          <textarea
            {...getFieldProps('notes')}
            className={`form-control ${
              formik.errors.notes && formik.touched.notes ? 'is-invalid' : ''
            }`}
            rows="6"
            placeholder="Add notes if needed"
          />

          {formik.errors.notes && formik.touched.notes ? (
            <span style={{ color: '#D53939' }}>{formik.errors.notes}</span>
          ) : null}
        </div>

      {/* Submit Button */}
      <button
        className="btn btn-primary"
        style={{ marginTop: '1em' }}
        type="submit"
      >
        Submit
      </button>
    </form>
  );
}
