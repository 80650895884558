import { SearchIcon } from '../components/Icons';

export default function SearchBox(props) {
  return (
    <div className="input-icon mb-1">
      <input
        type="text"
        className="form-control"
        placeholder="Search..."
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
      />
      <span className="input-icon-addon">
        <SearchIcon />
      </span>
    </div>
  );
}
