import { ArrowLeftIcon } from './Icons';

export default function Error(props) {
  let title = props.title || 'Oops...';
  let name = props.name || 'There was a problem!';
  let description =
    props.description ||
    'We are sorry but there is a problem with this page. Please try loading the page again.';

  return (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <div className="container-tight py-6">
        <div className="empty">
          <div className="empty-header">{title}</div>
          <p className="empty-title">{name}</p>
          <p className="empty-subtitle text-muted">{description}</p>
          <div className="empty-action">
            <a href="./." className="btn btn-primary">
              <ArrowLeftIcon />
              Take me back
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
