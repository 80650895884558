import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import TruckForm from './TruckForm';
import config from '../../config';
import Error from '../../components/Error';

const GET_TRUCK = gql`
  query GetTruck($id: Int!) {
    getTruck(id: $id) {
      id
      enabled
      truck_number
      state {
        id
        key
        name
      }
      entity {
        id
      }
      station {
        id
      }
      location
      exclude_from_settlement
      notes
      created
    }
  }
`;

const GET_STATES = gql`
  query {
    getStates {
      id
      enabled
      key
      name
    }
  }
`;

const GET_ENTITIES = gql`
  query {
    getEntities {
      id
      enabled
      name
    }
  }
`;

const GET_STATIONS = gql`
  query {
    getStations {
      id
      enabled
      name
    }
  }
`;

const ADD_TRUCK = gql`
  mutation CreateTruck(
    $truckNumber: Int!
    $enabled: Boolean!
    $truckState: Int!
    $truckEntity: Int!
    $truckStation: Int!
    $notes: String
    $excludeFromSettlement: Boolean!
  ) {
    createTruck(
      truck: {
        entity_id: $truckEntity
        station_id: $truckStation
        truck_number: $truckNumber
        enabled: $enabled
        exclude_from_settlement: $excludeFromSettlement
        state_id: $truckState
        notes: $notes
      }
    ) {
      id
      enabled
      truck_number
    }
  }
`;

const UPDATE_TRUCK = gql`
  mutation UpdateTruck(
    $id: Int!
    $enabled: Boolean!
    $truckState: Int!
    $truckEntity: Int!
    $truckStation: Int!
    $notes: String
    $excludeFromSettlement: Boolean!
  ) {
    updateTruck(
      id: $id
      args: {
        enabled: $enabled
        entity_id: $truckEntity
        station_id: $truckStation
        exclude_from_settlement: $excludeFromSettlement
        state_id: $truckState
        notes: $notes
      }
    ) {
      id
      enabled
      truck_number
    }
  }
`;

export default function AddEditTruck(props) {
  const [getTruck, truck] = useLazyQuery(GET_TRUCK, {
    fetchPolicy: 'no-cache',
  });
  const states = useQuery(GET_STATES);
  const entities = useQuery(GET_ENTITIES);
  const stations = useQuery(GET_STATIONS);
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  let history = useHistory();

  let { id } = useParams();

  // Load Truck details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      getTruck({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addTruck] = useMutation(ADD_TRUCK);
  const [updateTruck] = useMutation(UPDATE_TRUCK);

  function handleUpdateTruck(values) {
    let {
      truckState,
      isThisTruckActive,
      excludeFromSettlement,
      truckEntity,
      truckStation,
      notes,
    } = values;

    updateTruck({
      variables: {
        id: parseInt(id),
        enabled: isThisTruckActive,
        truckState: parseInt(truckState),
        excludeFromSettlement,
        truckEntity: parseInt(truckEntity),
        truckStation: parseInt(truckStation),
        notes,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/trucks');
      }, config.saveDialogTimeout);
    });
  }

  function handleAddTruck(values) {
    let {
      truckNumber,
      truckState,
      isThisTruckActive,
      excludeFromSettlement,
      truckEntity,
      truckStation,
      notes,
    } = values;

    addTruck({
      variables: {
        truckNumber: parseInt(truckNumber),
        enabled: isThisTruckActive,
        truckState: parseInt(truckState),
        excludeFromSettlement,
        truckEntity: parseInt(truckEntity),
        truckStation: parseInt(truckStation),
        notes
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/trucks');
      }, 1000);
    });
  }

  let isReadyToAdd =
    props.isAdding &&
    !states.loading &&
    states.called &&
    !entities.loading &&
    entities.called &&
    !stations.loading &&
    stations.called;

  let isReadyToEdit =
    !props.isAdding &&
    !states.loading &&
    states.called &&
    !entities.loading &&
    entities.called &&
    !stations.loading &&
    stations.called &&
    !truck.loading &&
    truck.called;

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  return (
    <div>
      {isReadyToAdd && (
        <TruckForm
          isAdding={props.isAdding}
          states={states.data.getStates}
          entities={entities.data.getEntities}
          stations={stations.data.getStations}
          handleSubmit={handleAddTruck}
        />
      )}

      {isReadyToEdit && (
        <TruckForm
          isAdding={props.isAdding}
          states={states.data.getStates}
          entities={entities.data.getEntities}
          stations={stations.data.getStations}
          handleSubmit={handleUpdateTruck}
          initialValues={truck.data.getTruck}
        />
      )}

      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </div>
  );
}
