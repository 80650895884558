import React, { useState } from 'react';
import PropTypes from 'prop-types';
import fetcher from '../fetcher';
import client from '../apolloClient';

async function loginUser(credentials) {
  return fetcher('/users/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then((data) => data);
}

const styles = {
  invalidLogin: {
    color: 'red',
    marginBottom: '2em',
  },
};

export default function Login({ setToken }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [isInvalidLogin, setIsInvalidLogin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = await loginUser({ username, password });

      setIsInvalidLogin(false);

      client.resetStore();

      setToken(token.jwt);
    } catch (err) {
      setIsInvalidLogin(true);
    }
  };

  return (
    <div className="flex-fill d-flex flex-column justify-content-center py-4">
      <div className="container-tight py-6">
        <div className="text-center mb-4">
          <a href=".">
            <img
              alt="logo"
              style={{ padding: '1em', width: '50%' }}
              src="/logo-dark.svg"
            />
          </a>
        </div>
        <form
          className="card card-md"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className="card-body">
            <h2 className="card-title text-center mb-4">
              Login to your account
            </h2>
            {isInvalidLogin && (
              <div style={styles.invalidLogin} className="text-center">
                Username and/or Password is incorrect
              </div>
            )}
            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input
                type="username"
                className="form-control"
                placeholder="Enter email"
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="none"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label className="form-label">Password</label>
              <div className="input-group input-group-flat">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  autoCorrect="off"
                  autoComplete="off"
                  autoCapitalize="none"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="form-footer">
              <button type="submit" className="btn btn-primary w-100">
                Sign in
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
