import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import useFilter from '../../hooks/useFilter';
import { EditIcon, PlusIcon } from '../../components/Icons';

import Error from '../../components/Error';
import SearchField from '../../components/SearchField';

const GET_STATEMENTS = gql`
  query GetStatements {
    getStatements {
      id
      enabled
      first_delivery
      last_delivery
      linehaul_records
      spot_records
      created
      entity {
        id
        name
      }
    }
  }
`;

export default function Statements() {
  const statements = useQuery(GET_STATEMENTS, {
    fetchPolicy: 'no-cache',
  });
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { isTokenExpired, deleteToken, hasPermission } = useToken();
  const { filter } = useFilter();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (statements.data) {
      let filteredData = filter(statements.data.getStatements, searchValue, [
        'id',
        'linehaul_records',
        'spot_records'
      ]);

      setFilteredData(filteredData);
    }
  }, [searchValue, statements.data]);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  if (statements.loading) return <h3>Loading...</h3>;

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Entity',
      selector: (row) => row.entity.name,
      sortable: true,
    },
    {
      name: 'First Delivery',
      selector: (row) => row.first_delivery,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.first_delivery)).toUTC();

        return date.toFormat('MM/dd/yyyy');
      },
      width: '140px',
    },
    {
      name: 'Last Delivery',
      selector: (row) => row.last_delivery,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.last_delivery)).toUTC();

        return date.toFormat('MM/dd/yyyy');
      },
      width: '140px',
    },
    {
      name: 'Linehaul Trips',
      selector: (row) => row.linehaul_records,
      sortable: true,
    },
    {
      name: 'Spot Trips',
      selector: (row) => row.spot_records,
      sortable: true,
    },
    {
      name: 'Created',
      selector: (row) => row.created,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.created)).toUTC();

        return date.toFormat('MM/dd/yyyy');
      },
      width: '140px',
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Link to={`/statements/${row.id}`} className="btn btn-link">
          <EditIcon /> Review
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <SearchField value={searchValue} setValue={setSearchValue} />
        </div>

        <div className="col-md-6" style={{ textAlign: 'right' }}>
          <Link
            to="/statements/add"
            className="btn btn-outline-success btn-pill float-right"
            style={{ marginLeft: 'auto' }}
          >
            <PlusIcon /> Add Statement
          </Link>
        </div>
      </div>

      <div className="card mt-2 mb-1">
        <DataTable data={filteredData} columns={columns} pagination striped />
      </div>
    </>
  );
}
