import React from 'react';
import DataTable from 'react-data-table-component';
import { DateTime } from 'luxon';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';

import 'react-toastify/dist/ReactToastify.css';

const GET_PAYROLLCYCLES = gql`
  query GetPayrollCycles {
    getPayrollCycles {
      id
      week_start
      week_end
      lock
      enabled
      created
    }
  }
`;

const UPDATE_PAYROLLCYCLE = gql`
  mutation UpdatePayrollCycle($id: Int!, $lock: Int!) {
    updatePayrollCycle(id: $id, args: { lock: $lock }) {
      id
      week_start
      week_end
      lock
      enabled
      created
    }
  }
`;

export default function PayrollCycles() {
  const { loading, data } = useQuery(GET_PAYROLLCYCLES);
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  const [updatePayrollCycle] = useMutation(UPDATE_PAYROLLCYCLE);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  function handleLockToggle(e, row) {
    let payrollCycleId = parseInt(row.id);

    let invertedPayrollCycleLock = row.lock === 0 ? 1 : 0;

    updatePayrollCycle({
      variables: {
        id: payrollCycleId,
        lock: invertedPayrollCycleLock,
      },
    }).then(() => {
      let lockActionVerb =
        invertedPayrollCycleLock === 1 ? 'Locked' : 'Unlocked';

      toast(`${lockActionVerb} Payroll Cycle`);
    });
  }

  const columns = [
    {
      name: 'Week Start',
      selector: (row) => row.week_start,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.week_start), {
          zone: 'utc',
        });

        return date.toFormat('MM/dd/yyyy');
      },
    },
    {
      name: 'Week End',
      selector: (row) => row.week_end,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.week_end), {
          zone: 'utc',
        });

        return date.toFormat('MM/dd/yyyy');
      },
    },
    {
      name: 'Lock',
      selector: (row) => row.lock,
      sortable: true,
      cell: (row, index, column, id) => {
        let lockStateVerb = row.lock === 1 ? 'Locked' : 'Unlocked';

        return (
          <label className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={row.lock}
              onChange={(e) => handleLockToggle(e, row)}
            />
            <span className="form-check-label" style={{ fontSize: '.8em' }}>
              {lockStateVerb}
            </span>
          </label>
        );
      },
    },
  ];

  if (loading) return <h2>Loading...</h2>;

  let paginationPerPage = 10;

  // Calculate the page we need to start on to put today in view
  let today = DateTime.now();

  let currentPayrollCycleIndex = data.getPayrollCycles.findIndex(
    (x) => x.week_start >= today.ts
  );

  let startingPage = Math.ceil(currentPayrollCycleIndex / paginationPerPage);

  return (
    <>
      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />

      <div className="card mt-2 mb-1">
        <DataTable
          className="table card-table"
          data={data.getPayrollCycles}
          columns={columns}
          pagination
          striped
          paginationPerPage={paginationPerPage}
          paginationDefaultPage={startingPage}
        />
      </div>
    </>
  );
}
