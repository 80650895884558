import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import { EditIcon, FileDownloadIcon } from '../../components/Icons';
import config from '../../config';

const GET_PAYROLL_CYCLES = gql`
  query GetPayrollCycles {
    getPayrollCycles {
      id
      week_start
      week_end
    }
  }
`;

const GET_DRIVERS = gql`
  query GetDrivers {
    getDriversWhichUserCanSee {
      id
      enabled
      first_name
      last_name
    }
  }
`;

const GET_TRUCKS = gql`
  query GetTrucks {
    getTrucks {
      id
      enabled
      truck_number
    }
  }
`;

const GET_DELIVERIES = gql`
  query GetDeliveries($driver: Int, $truck: Int, $payrollCycle: Int) {
    getDeliveries(driver: $driver, truck: $truck, payrollCycle: $payrollCycle) {
      id
      enabled
      driver {
        id
        first_name
        last_name
        ee_number
      }
      truck {
        id
        state {
          key
        }
        station {
          name
        }
        entity {
          name
        }
        truck_number
      }
      trip_type {
        key
        name
      }
      notes {
        id
        note
        created
      }
      images {
        id
        created
      }
      avr
      linehaul_from
      linehaul_to
      delivery_date
      created
    }
  }
`;

const columns = [
  {
    name: 'Delivery Date',
    selector: (row) => row.delivery_date,
    sortable: true,
    format: (row, index) => {
      // Parse UTC value and convert to EDT
      let date = DateTime.fromMillis(parseInt(row.delivery_date)).toUTC();

      return date.toFormat('MM/dd/yyyy');
    },
    width: '120px',
  },
  {
    name: 'Driver',
    selector: (row) => row.driver,
    format: (row, index) => {
      return `${row.driver.first_name} ${row.driver.last_name}`;
    },
    grow: 2,
  },
  {
    name: 'Truck',
    selector: (row) => row.truck.truck_number,
    sortable: true,
  },
  {
    name: 'Trip Type',
    selector: (row) => row.trip_type.name,
  },
  {
    name: 'AVR',
    selector: (row) => row.avr,
    sortable: true,
  },
  {
    name: 'From',
    selector: (row) => row.linehaul_from,
    sortable: true,
  },
  {
    name: 'To',
    selector: (row) => row.linehaul_to,
    sortable: true,
  },
  {
    name: 'Notes',
    cell: (row) => {
      let firstNote = null;

      if (row.notes.length > 0) {
        firstNote = row.notes[0].note;
      }

      return <div>{firstNote}</div>;
    },
    grow: 2,
    hide: 'sm',
  },
  {
    name: 'Created Date',
    selector: (row) => row.created,
    sortable: true,
    format: (row, index) => {
      // Parse UTC value and convert to EDT
      let date = DateTime.fromMillis(parseInt(row.created));

      return date.toFormat('MM/dd/yyyy h:mm a');
    },
    width: '220px',
  },
  {
    name: 'Image',
    cell: (row) => {
      let image = null;

      if (row.images.length > 0) {
        image = row.images[0];
      }

      if (image) {
        let imageUrl = `${config.apiRootUrl}/file/${image.id}`;
        return (
          <a href={imageUrl} rel="noreferrer" target="_blank">
            View
          </a>
        );
      } else {
        return <div>-</div>;
      }
    },
    grow: 2,
    hide: 'sm',
  },
  {
    name: 'Actions',
    cell: (row) => {
      return (
        <>
          {row.isEditVisible && (
            <Link to={`/deliveries/${row.id}`} className="btn btn-link">
              <EditIcon /> Edit
            </Link>
          )}
        </>
      );
    },
  },
];

export default function Deliveries() {
  const [getDeliveries, deliveries] = useLazyQuery(GET_DELIVERIES, {
    fetchPolicy: 'no-cache',
  });

  const drivers = useQuery(GET_DRIVERS, {
    fetchPolicy: 'no-cache',
  });

  const trucks = useQuery(GET_TRUCKS, {
    fetchPolicy: 'no-cache',
  });

  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  const { loading: payrollCyclesLoading, data: payrollCyclesData } =
    useQuery(GET_PAYROLL_CYCLES);

  // Filters
  const [driverFilter, setDriverFilter] = useState();
  const [payrollCycleFilter, setPayrollCycleFilter] = useState();
  const [truckFilter, setTruckFilter] = useState();
  const [hasImagesFilter, setHasImagesFilter] = useState();

  const [currentPayrollCycleIndex, setCurrentPayrollCycleIndex] =
    useState(null);

  const [data, setData] = useState([]);

  const [uniqueDrivers, setUniqueDrivers] = useState([]);
  const [uniqueTrucks, setUniqueTrucks] = useState([]);

  const { isTokenExpired, deleteToken, getUserId, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (payrollCyclesData !== undefined) {
      // Calculate the page we need to start on to put today in view
      let today = DateTime.local();

      // Get start of day
      let localToday = DateTime.fromObject({
        year: today.year,
        month: today.month,
        day: today.day,
      });

      let payrollIndex = payrollCyclesData.getPayrollCycles.findIndex(
        (x) => x.week_start >= localToday.ts
      );

      setCurrentPayrollCycleIndex(payrollIndex);
      setPayrollCycleFilter(payrollIndex);
    }
  }, [payrollCyclesData]);

  useEffect(() => {
    if (trucks.data !== undefined) {
      setUniqueTrucks(trucks.data.getTrucks);
    }
  }, [trucks]);

  useEffect(() => {
    if (drivers.data !== undefined) {
      setUniqueDrivers(drivers.data.getDriversWhichUserCanSee);
    }
  }, [drivers]);

  useEffect(() => {
    setTableLoading(true);

    // Make sure currentPayrollCycleIndex is set first!
    let payrollCycleId = payrollCycleFilter;

    if (payrollCycleId !== null && payrollCycleId !== undefined) {
      getDeliveries({
        variables: {
          driver: driverFilter,
          truck: truckFilter,
          payrollCycle: payrollCycleId,
        },
      });
    }
  }, [
    driverFilter,
    truckFilter,
    payrollCycleFilter,
    hasImagesFilter,
    currentPayrollCycleIndex,
  ]);

  useEffect(() => {
    if (deliveries.data !== undefined) {
      let appendedData = deliveries.data.getDeliveries.map((x) => {
        let userId = getUserId();

        // Append edit visibility
        let isEditVisible = false;

        if (x.driver.id === userId) {
          isEditVisible = true;
        }

        if (hasPermission('isadmin')) {
          isEditVisible = true;
        }

        return {
          isEditVisible,
          ...x,
        };
      });

      let filteredRecords = appendedData.filter((x) => {
        let showRecord = true;

        // Filter by Has Images
        switch (hasImagesFilter) {
          case 'yes':
            if (x.images.length === 0) {
              showRecord = false;
            }
            break;
          case 'no':
            if (x.images.length > 0) {
              showRecord = false;
            }
            break;
          default:
            // Don't filter
            break;
        }

        return showRecord;
      });

      setData(filteredRecords);
      setTableLoading(false);
      setLoading(false);
    }
  }, [deliveries]);

  let loadingElement = (
    <h4 style={{ padding: '1em', marginBottom: '0px' }}>
      Loading<span className="animated-dots"></span>
    </h4>
  );

  if (loading || payrollCyclesLoading) return loadingElement;

  let driverOptions = uniqueDrivers
    .filter((x) => {
      return true;
    })
    .sort((a, b) => {
      return a.first_name.localeCompare(b.first_name);
    })
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.first_name} {x.last_name}
        </option>
      );
    });

  let truckOptions = uniqueTrucks
    .filter((x) => {
      if (hasPermission('isadmin')) {
        return true;
      }

      let matchedTruck = data.filter((y) => y.truck.id === x.id);

      return matchedTruck.length > 0;
    })
    .sort((a, b) => {
      return a.truck_number - b.truck_number;
    })
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.truck_number}
        </option>
      );
    });

  let payrollCycles = payrollCyclesData.getPayrollCycles.map((x) => {
    let weekStart = DateTime.fromMillis(parseInt(x.week_start), {
      zone: 'utc',
    }).toFormat('MM/dd/yyyy');

    let weekEnd = DateTime.fromMillis(parseInt(x.week_end), {
      zone: 'utc',
    }).toFormat('MM/dd/yyyy');

    return (
      <option key={x.id} value={x.id}>
        {weekStart} - {weekEnd}
      </option>
    );
  });

  const handleFilterChange = (e) => {
    switch (e.target.name) {
      case 'driver':
        let parsedDriver = parseInt(e.target.value);

        if (!isNaN(parsedDriver)) {
          setDriverFilter(parsedDriver);
        } else {
          setDriverFilter(undefined);
        }

        break;
      case 'truck':
        let parsedTruck = parseInt(e.target.value);

        if (!isNaN(parsedTruck)) {
          setTruckFilter(parsedTruck);
        } else {
          setTruckFilter(undefined);
        }

        break;
      case 'payrollcycle':
        setPayrollCycleFilter(parseInt(e.target.value));
        break;
      case 'hasimages':
        setHasImagesFilter(e.target.value);
        break;
      default:
        break;
    }
  };

  function convertArray(array) {
    // Convert data to correct format
    // Sort it!
    let exportData = array
      .sort((a, b) => {
        let aName = `${a.driver.first_name} ${a.driver.last_name}`;
        let bName = `${b.driver.first_name} ${b.driver.last_name}`;

        return aName.localeCompare(bName) || a.id - b.id;
      })
      .map((x, index) => {
        // Handle Notes
        let note = '';

        if (x.notes.length > 0) {
          note = x.notes[0].note;
        }

        // Handle Timestamp
        let timestamp = DateTime.fromMillis(parseInt(x.created));

        // Handle Delivery Date
        let deliveryDate = DateTime.fromMillis(
          parseInt(x.delivery_date)
        ).toUTC();

        // Handle From
        let from = '';
        if (x.trip_type.key === 'linehaul') {
          from = x.linehaul_from;
        }

        // Handle To
        let to = '';
        if (x.trip_type.key === 'linehaul') {
          to = x.linehaul_to;
        } else {
          to = x.avr;
        }

        return {
          Sequence: index + 1,
          'Truck State': x.truck.state.key,
          Station: x.truck.station.name,
          Entity: x.truck.entity.name,
          'Last Name': x.driver.last_name,
          Employee: `${x.driver.first_name} ${x.driver.last_name}`,
          'Employee Number': x.driver.ee_number,
          Date: deliveryDate.toFormat('MM/dd/yyyy'),
          Day: deliveryDate.toFormat('cccc'),
          Truck: x.truck.truck_number,
          'Trip Type': x.trip_type.name,
          From: from,
          To: to,
          Timestamp: timestamp.toFormat('MM/dd/yyyy h:mm a'),
          Notes: note,
        };
      });

    return exportData;
  }

  function downloadCSV(array, filename = 'export') {
    let converted = convertArray(array);

    let now = DateTime.now().toFormat('yyyy-MM-dd HH-mm-ss');
    const finalFilename = `${filename} - ${now}.xlsx`;

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');

    fetch(`${config.apiRootUrl}/export`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ data: converted }),
    })
      .then(async (res) => ({
        filename: finalFilename,
        blob: await res.blob(),
      }))
      .then((resObj) => {
        // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        const newBlob = new Blob([resObj.blob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);

          let link = document.createElement('a');
          link.href = objUrl;
          link.download = resObj.filename;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        }
      })
      .catch((error) => {
        console.log('DOWNLOAD ERROR', error);
      });
  }

  return (
    <>
      {!loading && (
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* Driver */}
                <div className="form-group col-md-4 mb-3">
                  <label className="form-label">Driver</label>

                  <select
                    name="driver"
                    className="form-select"
                    type="text"
                    onChange={handleFilterChange}
                  >
                    <option value="">Select</option>

                    {/* Pull in all other drivers */}
                    {driverOptions}
                  </select>
                </div>

                {/* Truck */}
                <div className="form-group col-md-4 mb-3">
                  <label className="form-label">Truck</label>

                  <select
                    name="truck"
                    className="form-select"
                    type="text"
                    onChange={handleFilterChange}
                  >
                    <option value="">Select</option>

                    {/* Pull in all other trucks */}
                    {truckOptions}
                  </select>
                </div>

                {/* Payroll Cycle */}
                <div className="form-group col-md-4 mb-3">
                  <label className="form-label">Payroll Cycle</label>

                  <select
                    name="payrollcycle"
                    className="form-select"
                    type="text"
                    onChange={handleFilterChange}
                    defaultValue={currentPayrollCycleIndex}
                  >
                    <option value="">Select</option>

                    {/* Pull in all other Payroll Cycles */}
                    {payrollCycles}
                  </select>
                </div>

                {/* Has Images */}
                <div className="form-group col-md-4 mb-3">
                  <label className="form-label">Has Images</label>

                  <select
                    name="hasimages"
                    className="form-select"
                    type="text"
                    onChange={handleFilterChange}
                  >
                    <option value="">Any</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-6" style={{ textAlign: 'right' }}>
              <button
                className="btn btn-outline-success btn-pill float-right"
                style={{ marginLeft: 'auto' }}
                onClick={() => downloadCSV(data, 'Payroll')}
              >
                <FileDownloadIcon /> Export
              </button>
            </div>
          </div>

          <div className="card mt-2 mb-1">
            {tableLoading && loadingElement}

            {!tableLoading && (
              <DataTable
                className="table card-table"
                data={data}
                columns={columns}
                pagination
                striped
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
