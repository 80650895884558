import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import StationForm from './StationForm.jsx';
import config from '../../config';
import Error from '../../components/Error';

const GET_STATION = gql`
  query GetStation($id: Int!) {
    getStation(id: $id) {
      id
      enabled
      name
      budget
      notification_emails
      created
    }
  }
`;

const ADD_STATION = gql`
  mutation CreateStation(
    $name: String!
    $budget: Int
    $notification_emails: String
  ) {
    createStation(
      station: {
        name: $name
        budget: $budget
        notification_emails: $notification_emails
      }
    ) {
      id
      enabled
      name
      budget
      notification_emails
      created
    }
  }
`;

const UPDATE_STATION = gql`
  mutation UpdateStation(
    $id: Int!
    $enabled: Boolean
    $name: String
    $budget: Int
    $notification_emails: String
  ) {
    updateStation(
      id: $id
      args: {
        enabled: $enabled
        name: $name
        budget: $budget
        notification_emails: $notification_emails
      }
    ) {
      id
      enabled
      name
      budget
      notification_emails
      created
    }
  }
`;

export default function AddEditStation(props) {
  const [getStation, station] = useLazyQuery(GET_STATION, {
    fetchPolicy: 'no-cache',
  });
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  let history = useHistory();

  let { id } = useParams();

  // Load Station details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      getStation({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addStation] = useMutation(ADD_STATION);
  const [updateStation] = useMutation(UPDATE_STATION);

  function handleUpdateStation(values) {
    let { enabled, name, budget, notification_emails } = values;

    updateStation({
      variables: {
        id: parseInt(id),
        enabled,
        name,
        budget: parseInt(budget),
        notification_emails,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/stations');
      }, config.saveDialogTimeout);
    });
  }

  function handleAddStation(values) {
    let { name, budget, notification_emails } = values;

    addStation({
      variables: {
        name,
        budget: parseInt(budget),
        notification_emails,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/stations');
      }, 1000);
    });
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  return (
    <div>
      {props.isAdding && (
        <StationForm
          isAdding={props.isAdding}
          handleSubmit={handleAddStation}
        />
      )}

      {!props.isAdding && !station.loading && station.called && (
        <StationForm
          isAdding={props.isAdding}
          handleSubmit={handleUpdateStation}
          initialValues={station.data.getStation}
        />
      )}

      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </div>
  );
}
