import { useState } from 'react';
import jsonwebtoken from 'jsonwebtoken';

export default function useToken() {
  const getToken = () => {
    const token = localStorage.getItem('token');

    return token;
  };

  const getUserId = () => {
    const decoded = getDecodedToken();

    if (decoded !== null) {
      return decoded.userId;
    }

    return null;
  };

  const isFakeUser = () => {
    const decoded = getDecodedToken();

    if (decoded !== null) {
      return decoded.userId === 297;
    }

    return false;
  }

  const getEmail = () => {
    const decoded = getDecodedToken();

    return decoded.username;
  }

  const getDecodedToken = () => {
    const token = getToken();

    let decoded = jsonwebtoken.decode(token);

    return decoded;
  };

  const hasPermission = (key) => {
    let decoded = getDecodedToken();

    if (!decoded) return false;

    let permissions = decoded.data.permissionKeys;

    return permissions.indexOf(key) > -1;
  };

  const hasReviewRolePermission = () => {
    let decoded = getDecodedToken();

    if(!decoded) return false;

    let permissions = decoded.data.permissionKeys;

    console.dir(permissions);

    return false;
  }

  const [token, setToken] = useState(getToken());

  const isTokenExpired = () => {
    let decoded = getDecodedToken();

    if (!jsonwebtoken) return true;

    if (!decoded) return true;

    return Date.now() >= decoded.exp * 1000;
  };

  const saveToken = (token) => {
    localStorage.setItem('token', token);
    setToken(token);
  };

  const deleteToken = () => {
    localStorage.removeItem('token');
    window.location.replace('/');
  };

  return {
    deleteToken,
    getDecodedToken,
    getUserId,
    getEmail,
    hasPermission,
    hasReviewRolePermission,
    isTokenExpired,
    setToken: saveToken,
    token,
    isFakeUser
  };
}
