import { useState } from 'react';
import { useFormik } from 'formik';
import Message from '../../components/Message';
import { fetchWithTokenFromAPIService } from '../../fetcher';
import { ToastContainer, toast } from 'react-toastify';

export default function FuelLocationsForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [selectedFile, setSelectedFile] = useState();

  let initialValues = {};

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      if (selectedFile !== undefined) {
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
          setSubmitting(true);

          let response = await fetchWithTokenFromAPIService(
            '/uploadfuelpdf',
            {
              method: 'POST',
              body: formData,
            },
            false
          );

          if (response.status !== 200) {
            props.handleSubmit(
              'There was a problem processing the file. Please make sure it is a PDF file in the proper format!'
            );
          } else {
            props.handleSubmit();
          }
        } catch (err) {
          props.handleSubmit(err);
        }
      }
    },
  });

  if (submitting) {
    let title = 'Uploading';
    let description = 'Please wait while the PDF is being uploaded';

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* File Chooser */}
        <>
          <p>
            Click <b>Choose File</b> and select the Fuel Locations file to
            upload.{' '}
            <b>
              Please note that file must be the original .pdf file which is
              provided by FedEx
            </b>
            . Other formats are not supported!
          </p>

          <div className="form-group col-md-12 mb-3">
            <input type="file" accept=".pdf" onChange={onFileChange} />
          </div>
        </>

        {/* Submit Button */}
        <button
          className="btn btn-primary"
          style={{ marginTop: '1em' }}
          type="submit"
        >
          Submit
        </button>
      </form>

      <ToastContainer
        autoClose={7500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </>
  );
}
