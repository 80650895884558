import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import PostForm from './PostForm';
import config from '../../config';
import Error from '../../components/Error';

const GET_POST = gql`
  query GetPost($id: Int!) {
    getPost(id: $id) {
      id
      enabled
      created_by {
        id
      }
      title
      body
      created
    }
  }
`;

const ADD_POST = gql`
  mutation CreatePost(
    $title: String!
    $body: String!
    $created_by: Int!
    $category_id: Int!
  ) {
    createPost(
      post: {
        title: $title
        body: $body
        created_by: $created_by
        category_id: $category_id
      }
    ) {
      id
      title
      body
      created
    }
  }
`;

const UPDATE_POST = gql`
  mutation UpdatePost(
    $id: Int!
    $enabled: Boolean
    $title: String
    $body: String
  ) {
    updatePost(
      id: $id
      args: { enabled: $enabled, title: $title, body: $body }
    ) {
      id
      title
      body
      created
    }
  }
`;

const CREATE_POST_IMAGE = gql`
  mutation CreatePostImage($post_id: Int!, $user_id: Int!, $image_id: Int!) {
    createPostImage(
      image: { post_id: $post_id, user_id: $user_id, image_id: $image_id }
    ) {
      id
    }
  }
`;

export default function AddEditPost(props) {
  const [getPost, post] = useLazyQuery(GET_POST, {
    fetchPolicy: 'no-cache',
  });
  const [addPostImage] = useMutation(CREATE_POST_IMAGE);
  const { isTokenExpired, deleteToken, hasPermission, getUserId } = useToken();

  let history = useHistory();

  let { id } = useParams();

  // Load post details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      getPost({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addPost] = useMutation(ADD_POST);
  const [updatePost] = useMutation(UPDATE_POST);

  function handleUpdatePost(values) {
    let { enabled, title, body } = values;

    updatePost({
      variables: {
        id: parseInt(id),
        enabled,
        title,
        body,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/posts');
      }, config.saveDialogTimeout);
    });
  }

  function handleDeletePost(id) {
    updatePost({
      variables: {
        id: parseInt(id),
        enabled: false,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/posts');
      }, config.saveDialogTimeout);
    });
  }

  async function handleAddPost(values, imageId) {
    let { title, body } = values;

    try {
      let result = await addPost({
        variables: {
          title,
          body,
          created_by: getUserId(),
          category_id: 1,
        },
      });

      if (imageId) {
        await addPostImage({
          variables: {
            post_id: result.data.createPost.id,
            user_id: getUserId(),
            image_id: imageId,
          },
        });
      }

      setTimeout(() => {
        history.push('/posts');
      }, 1000);
    } catch (err) {
      let errorDescription = 'There was a problem adding this Post';

      return <Error description={errorDescription} />;
    }
  }

  let postAuthorId = post.data?.getPost?.created_by?.id;

  const isEditing = !props.isAdding;
  const hasPostLoaded = !post.loading && post.called;
  const isPostMine = postAuthorId === getUserId();
  const amIAnAdmin = hasPermission('isadmin');

  if (isEditing && hasPostLoaded && !isPostMine && !amIAnAdmin) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to edit this post."
      />
    );
  }

  return (
    <div>
      {props.isAdding && (
        <PostForm isAdding={props.isAdding} handleSubmit={handleAddPost} />
      )}

      {!props.isAdding && !post.loading && post.called && (
        <PostForm
          isAdding={props.isAdding}
          handleSubmit={handleUpdatePost}
          handleDelete={handleDeletePost}
          initialValues={post.data.getPost}
        />
      )}

      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </div>
  );
}
