import config from './config';

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import PageWithHero from './components/PageWithHero';

// Deliveries
import Deliveries from './pages/deliveries/Deliveries';
import AddEditDelivery from './pages/deliveries/AddEditDelivery';

// Entities
import Entities from './pages/entities/Entities';
import AddEditEntity from './pages/entities/AddEditEntity';

// Posts
import Posts from './pages/posts/Posts';
import AddEditPost from './pages/posts/AddEditPost';
import ViewPost from './pages/posts/ViewPost';

// Fuel
import Fuel from './pages/fuel/Fuel';
import FuelLocations from './pages/fuel/FuelLocations';
import AddFuelLocations from './pages/fuel/AddFuelLocations';

// Statements
import Statements from './pages/statements/Statements';
import AddEditStatements from './pages/statements/AddEditStatement';
import ViewStatement from './pages/statements/ViewStatement';

// Stations
import Stations from './pages/stations/Stations';
import AddEditStation from './pages/stations/AddEditStation';
import AddEditBudget from './pages/stations/AddEditBudget';

// Trucks
import Trucks from './pages/trucks/Trucks';
import AddEditTruck from './pages/trucks/AddEditTruck';

// Payroll Cycles
import PayrollCycles from './pages/payrollcycles/PayrollCycles';

// Purchase Orders
import PurchaseOrders from './pages/purchaseorders/PurchaseOrders';
import AddEditPurchaseOrder from './pages/purchaseorders/AddEditPurchaseOrder';

// Users
import Users from './pages/users/Users';
import AddEditUser from './pages/users/AddEditUser';

// Vendors
import Vendors from './pages/vendors/Vendors';
import AddEditVendor from './pages/vendors/AddEditVendor';

// Reports
import TabularReports from './pages/reports/TabularReports';
import TabularReport from './pages/reports/TabularReport';

// Error
import Error from './components/Error';

export default function Router() {
  return (
    <Switch>
      <Route
        path="/"
        component={() => (
          <PageWithHero
            title={config.appName}
            subTitle="Internal Management System"
          >
            <Home />
          </PageWithHero>
        )}
        exact
      />

      {/* Deliveries */}
      <Route
        path="/deliveries"
        component={() => (
          <PageWithHero
            title="Deliveries"
            subTitle="Review Deliveries"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Deliveries', active: true },
            ]}
          >
            <Deliveries />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/deliveries/add"
        component={() => (
          <PageWithHero
            title="Add Delivery"
            subTitle="Add a new Delivery"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/deliveries', name: 'Deliveries', active: false },
              { name: 'Add Delivery', active: true },
            ]}
          >
            <AddEditDelivery isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/deliveries/:id"
        component={() => (
          <PageWithHero
            title="Edit Delivery"
            subTitle="Edit an existing Delivery"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/deliveries', name: 'Deliveries', active: false },
              { name: 'Edit Delivery', active: true },
            ]}
          >
            <AddEditDelivery isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      {/* Fuel Locations */}
      <Route
        path="/fuellocations"
        component={() => (
          <PageWithHero
            title="Fuel Locations"
            subTitle="Review Fuel Locations"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Fuel Locations', active: true },
            ]}
          >
            <FuelLocations />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/fuellocations/add"
        component={() => (
          <PageWithHero
            title="Upload Fuel Locations"
            subTitle="Upload a new Fuel Location PDF"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/fuellocations', name: 'Fuel Locations', active: false },
              { name: 'Add Fuel Locations', active: true },
            ]}
          >
            <AddFuelLocations />
          </PageWithHero>
        )}
        exact
      />

      {/* Statements */}
      <Route
        path="/statements"
        component={() => (
          <PageWithHero
            title="Statements"
            subTitle="Review Statements"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Statements', active: true },
            ]}
          >
            <Statements />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/statements/add"
        component={() => (
          <PageWithHero
            title="Add Statement"
            subTitle="Add a new Statement"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/statements', name: 'Statements', active: false },
              { name: 'Add Statement', active: true },
            ]}
          >
            <AddEditStatements isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/statements/:id"
        component={() => (
          <PageWithHero
            title="View Statement"
            subTitle="View an existing Statement"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/statements', name: 'Statements', active: false },
              { name: 'View Statement', active: true },
            ]}
          >
            <ViewStatement />
          </PageWithHero>
        )}
        exact
      />

      {/* Stations */}
      <Route
        path="/stations"
        component={() => (
          <PageWithHero
            title="Stations"
            subTitle="Review Stations"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Stations', active: true },
            ]}
          >
            <Stations />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/stations/add"
        component={() => (
          <PageWithHero
            title="Add Station"
            subTitle="Add a new Station"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/stations', name: 'Stations', active: false },
              { name: 'Add Station', active: true },
            ]}
          >
            <AddEditStation isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/stations/:id"
        component={() => (
          <PageWithHero
            title="Edit Station"
            subTitle="Edit an existing Station"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/stations', name: 'Stations', active: false },
              { name: 'Edit Station', active: true },
            ]}
          >
            <AddEditStation isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/stations/:id/budgets/add"
        component={() => (
          <PageWithHero
            title="Add Budget"
            subTitle="Add a new Budget"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/stations', name: 'Stations', active: false },
              { name: 'Add Budget', active: true },
            ]}
          >
            <AddEditBudget isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/stations/:id/budgets/:budgetId"
        component={() => (
          <PageWithHero
            title="Edit Budget"
            subTitle="Edit an existing Budget"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/stations', name: 'Stations', active: false },
              { name: 'Edit Budget', active: true },
            ]}
          >
            <AddEditBudget isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      {/* Entities */}
      <Route
        path="/entities"
        component={() => (
          <PageWithHero
            title="Entities"
            subTitle="Review Entites"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Entities', active: true },
            ]}
          >
            <Entities />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/entities/add"
        component={() => (
          <PageWithHero
            title="Add Entity"
            subTitle="Add a new Entity"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/entities', name: 'Entities', active: false },
              { name: 'Add Entity', active: true },
            ]}
          >
            <AddEditEntity isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/entities/:id"
        component={() => (
          <PageWithHero
            title="Edit Entity"
            subTitle="Edit an existing Entity"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/entities', name: 'Entities', active: false },
              { name: 'Edit Entity', active: true },
            ]}
          >
            <AddEditEntity isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      {/* Posts */}
      <Route
        path="/posts"
        component={() => (
          <PageWithHero
            title="Posts"
            subTitle="Review Posts"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Posts', active: true },
            ]}
          >
            <Posts />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/posts/add"
        component={() => (
          <PageWithHero
            title="Add Post"
            subTitle="Add a new Post"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/posts', name: 'Posts', active: false },
              { name: 'Add Post', active: true },
            ]}
          >
            <AddEditPost isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/posts/:id"
        component={() => (
          <PageWithHero
            title="Edit Post"
            subTitle="Edit an existing Post"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/posts', name: 'Posts', active: false },
              { name: 'Edit Post', active: true },
            ]}
          >
            <AddEditPost isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/posts/:id/view"
        component={() => (
          <PageWithHero
            title="View Post"
            subTitle="View a Post"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/posts', name: 'Posts', active: false },
              { name: 'View Post', active: true },
            ]}
          >
            <ViewPost />
          </PageWithHero>
        )}
        exact
      />

      {/* Fuel */}
      <Route
        path="/fuel"
        component={() => (
          <PageWithHero
            title="Fuel Locations"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Fuel', active: true },
            ]}
          >
            <Fuel />
          </PageWithHero>
        )}
        exact
      />

      {/* Trucks */}
      <Route
        path="/trucks"
        component={() => (
          <PageWithHero
            title="Trucks"
            subTitle="Review Trucks"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Trucks', active: true },
            ]}
          >
            <Trucks />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/trucks/add"
        component={() => (
          <PageWithHero
            title="Add Truck"
            subTitle="Add a new Truck"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/trucks', name: 'Trucks', active: false },
              { name: 'Add Truck', active: true },
            ]}
          >
            <AddEditTruck isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/trucks/:id"
        component={() => (
          <PageWithHero
            title="Edit Truck"
            subTitle="Edit an existing Truck"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/trucks', name: 'Trucks', active: false },
              { name: 'Edit Truck', active: true },
            ]}
          >
            <AddEditTruck isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      {/* Payroll Cycles */}
      <Route
        path="/payrollcycles"
        component={() => (
          <PageWithHero
            title="Payroll Cycles"
            subTitle="View and Manage Payroll Cycles"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Payroll Cycles', active: true },
            ]}
          >
            <PayrollCycles />
          </PageWithHero>
        )}
        exact
      />

      {/* Purchase Orders */}
      <Route
        path="/purchaseorders"
        component={() => (
          <PageWithHero
            title="Purchase Orders"
            subTitle="Review Purchase Orders"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Purchase Orders', active: true },
            ]}
          >
            <PurchaseOrders />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/purchaseorders/add"
        component={() => (
          <PageWithHero
            title="Add Purchase Order"
            subTitle="Add a new Purchase Order"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              {
                url: '/purchaseorders',
                name: 'Purchase Orders',
                active: false,
              },
              { name: 'Add Purchase Order', active: true },
            ]}
          >
            <AddEditPurchaseOrder isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/purchaseorders/:id"
        component={() => (
          <PageWithHero
            title="Edit Purchase Order"
            subTitle="Edit an existing Purchase Order"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              {
                url: '/purchaseorders',
                name: 'Purchase Orders',
                active: false,
              },
              { name: 'Edit Purchase Order', active: true },
            ]}
          >
            <AddEditPurchaseOrder isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      {/* Users */}
      <Route
        path="/users"
        component={() => (
          <PageWithHero
            title="Users"
            subTitle="View and Manage Users"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Users', active: true },
            ]}
          >
            <Users />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/users/add"
        component={() => (
          <PageWithHero
            title="Add User"
            subTitle="Add a new User"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/users', name: 'Users', active: false },
              { name: 'Add User', active: true },
            ]}
          >
            <AddEditUser isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/users/:id"
        component={() => (
          <PageWithHero
            title="Edit User"
            subTitle="Edit an existing User"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/users', name: 'Users', active: false },
              { name: 'Edit User', active: true },
            ]}
          >
            <AddEditUser isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      {/* Vendors */}
      <Route
        path="/vendors"
        component={() => (
          <PageWithHero
            title="Vendors"
            subTitle="Review Vendors"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Vendors', active: true },
            ]}
          >
            <Vendors />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/vendors/add"
        component={() => (
          <PageWithHero
            title="Add Vendor"
            subTitle="Add a new Vendor"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/vendors', name: 'Vendors', active: false },
              { name: 'Add Vendors', active: true },
            ]}
          >
            <AddEditVendor isAdding={true} />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/vendors/:id"
        component={() => (
          <PageWithHero
            title="Edit Vendor"
            subTitle="Edit an existing Vendor"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { url: '/vendors', name: 'Vendors', active: false },
              { name: 'Edit Vendor', active: true },
            ]}
          >
            <AddEditVendor isAdding={false} />
          </PageWithHero>
        )}
        exact
      />

      {/* Reports */}
      <Route
        path="/reports/tabular"
        component={() => (
          <PageWithHero
            title="Tabular Reports"
            subTitle="Table based Reports"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              { name: 'Tabular Reports', active: true },
            ]}
          >
            <TabularReports />
          </PageWithHero>
        )}
        exact
      />

      <Route
        path="/reports/tabular/:id"
        component={() => (
          <PageWithHero
            title="Report"
            subTitle="View Report"
            breadcrumbs={[
              { url: '/', name: 'Home', active: false },
              {
                url: '/reports/tabular',
                name: 'Tabular Reports',
                active: false,
              },
              { name: 'Report', active: true },
            ]}
          >
            <TabularReport />
          </PageWithHero>
        )}
        exact
      />

      {/* Error */}
      <Route path="*">
        <Error
          title="404"
          name="Page not found."
          description="Sorry, but this page does not exist!"
        />
      </Route>
    </Switch>
  );
}
