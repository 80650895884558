import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DateTime } from 'luxon';
import { fetchWithTokenFromAPI2 } from '../../fetcher';
import useToken from '../../hooks/useToken';
import { AlertTriangleIcon } from '../../components/Icons';
import { getFakeFuelLocationDates, getFakeFuelLocations } from '../../fakeData';

export default function Fuel() {
  const [validFrom, setValidFrom] = useState('');
  const [validTo, setValidTo] = useState('');
  const [datesLoading, setDatesLoading] = useState(true);

  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);

  const { isTokenExpired, deleteToken, isFakeUser } = useToken();

  useEffect(() => {
    if(isFakeUser()) {
      setLocations(getFakeFuelLocations());
      setLocationsLoading(false);

      let fakeDates = getFakeFuelLocationDates();

      let startDate = DateTime.fromISO(fakeDates.startDate);
      let endDate = DateTime.fromISO(fakeDates.endDate);

      setValidFrom(startDate.toFormat('MM/dd/yyyy'));
      setValidTo(endDate.toFormat('MM/dd/yyyy'));

      setDatesLoading(false);
    } else {
      fetchWithTokenFromAPI2(
        '/fuellocations/active',
        {
          method: 'GET',
        },
        true,
      ).then(response => {
        setLocations(response);
        setLocationsLoading(false);
      });

      fetchWithTokenFromAPI2(
        '/fuellocations/dates',
        {
          method: 'GET',
        },
        true,
      ).then(response => {
        let startDate = DateTime.fromISO(response.startDate);
        let endDate = DateTime.fromISO(response.endDate);

        setValidFrom(startDate.toFormat('MM/dd/yyyy'));
        setValidTo(endDate.toFormat('MM/dd/yyyy'));

        setDatesLoading(false);
      });
    }
  }, []);

  if (isTokenExpired()) {
    deleteToken();
  }

  if (locationsLoading || datesLoading) return <h3>Loading...</h3>;

  function toCurrency(numberString) {
    let number = parseFloat(numberString);

    let response = 'Not available';

    if (!isNaN(number)) {
      response = '$' + number.toLocaleString('USD');
    }

    return response;
  }

  const columns = [
    {
      name: 'Site',
      selector: (row) => row.siteNumber,
      sortable: true,
      width: '70px',
    },
    {
      name: 'Abbrev',
      selector: (row) => row.abbreviation,
      sortable: true,
      width: '70px',
      compact: true,
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
      width: '50px',
      compact: true,
    },
    {
      name: 'Location',
      selector: (row) => row.location,
      sortable: true,
      compact: true,
    },
    {
      name: 'DEF $/gal',
      selector: (row) => row.defPrice,
      sortable: true,
      format: (row) => toCurrency(row.defPrice),
    },
    {
      name: 'Diesel $/gal',
      selector: (row) => row.dieselThisWeek,
      sortable: true,
      format: (row) => toCurrency(row.dieselThisWeek),
    },
  ];

  return (
    <>
      <p>
        Valid from <b>{validFrom}</b> to <b>{validTo}</b>
      </p>
      <div className="card-body fuel-pricing-scroll-notification">
        <div className="row align-items-center">
          <div className="col-auto">
            <span className="bg-yellow text-white avatar">
              <AlertTriangleIcon />
            </span>
          </div>
          <div className="col">
            <div className="font-weight-medium">Don't see pricing?</div>
            <div className="text-muted">
              If you can't see the prices for DEF and/or Diesel, scroll to the
              right!
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-2 mb-1">
        <DataTable data={locations} columns={columns} fixedHeader striped />
      </div>
    </>
  );
}
