import config from './config';

const fetchWithToken = (route, options) => {
  const url = `${config.apiRootUrl}${route}`;

  // Get jwt token
  let token = localStorage.getItem('token');

  options = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: token,
    },
  };

  return fetch(url, options).then((data) => data.json());
};

const fetchWithTokenFromAPIService = (
  route,
  options,
  expectResponse = true
) => {
  const url = `${config.apiSvcRootUrl}${route}`;

  // Get jwt token
  let token = localStorage.getItem('token');

  options = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: token,
    },
  };

  if (expectResponse) {
    return fetch(url, options).then((data) => data.json());
  } else {
    return fetch(url, options);
  }
};

const fetchWithTokenFromAPI2 = (
  route,
  options,
  expectResponse = true
) => {
  const url = `${config.api2Url}${route}`;

  // Get jwt token
  let token = localStorage.getItem('token');

  options = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: token,
    },
  };

  if (expectResponse) {
    return fetch(url, options).then((data) => data.json());
  } else {
    return fetch(url, options);
  }
};

const fetchWithoutToken = (route, options) => {
  const url = `${config.apiRootUrl}${route}`;

  return fetch(url, options).then((data) => data.json());
};

export {
  fetchWithoutToken as default,
  fetchWithTokenFromAPIService,
  fetchWithTokenFromAPI2,
  fetchWithToken,
};
