import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import useFilter from '../../hooks/useFilter';
import { EditIcon, PlusIcon } from '../../components/Icons';

import Error from '../../components/Error';
import SearchField from '../../components/SearchField';

const GET_TRUCKS = gql`
  query GetTrucks {
    getTrucks {
      id
      enabled
      truck_number
      state {
        id
        key
        name
      }
      entity {
        name
      }
      station {
        name
      }
      location
      exclude_from_settlement
      notes
      created
    }
  }
`;

export default function Trucks() {
  const trucks = useQuery(GET_TRUCKS, {
    fetchPolicy: 'no-cache',
  });
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { isTokenExpired, deleteToken, hasPermission } = useToken();
  const { filter } = useFilter();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (trucks.data) {
      let filteredData = filter(trucks.data.getTrucks, searchValue, [
        'truck_number',
      ]);

      setFilteredData(filteredData);
    }
  }, [searchValue, trucks.data]);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  if (trucks.loading) return <h3>Loading...</h3>;

  const columns = [
    {
      name: 'Truck Number',
      selector: (row) => row.truck_number,
      sortable: true,
    },
    {
      name: 'State',
      selector: (row) => row.state.name,
      sortable: true,
    },
    {
      name: 'Station',
      selector: (row) => row.station.name,
      sortable: true,
    },
    {
      name: 'Entity',
      selector: (row) => row.entity.name,
      sortable: true,
    },
    {
      name: 'Notes',
      selector: (row) => row.notes,
      sortable: true,
      maxWidth: '220px',
      hide: 'sm',
    },
    {
      name: 'Is Active',
      selector: (row) => row.enabled,
      sortable: true,
      cell: (row) => (
        <div>
          <span
            className={`badge ${row.enabled ? 'bg-success' : 'bg-danger'} me-1`}
          />
          {row.enabled ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Link to={`/trucks/${row.id}`} className="btn btn-link">
          <EditIcon /> Edit
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <SearchField value={searchValue} setValue={setSearchValue} />
        </div>

        <div className="col-md-6" style={{ textAlign: 'right' }}>
          <Link
            to="/trucks/add"
            className="btn btn-outline-success btn-pill float-right"
            style={{ marginLeft: 'auto' }}
          >
            <PlusIcon /> Add Truck
          </Link>
        </div>
      </div>

      <div className="card mt-2 mb-1">
        <DataTable data={filteredData} columns={columns} pagination striped />
      </div>
    </>
  );
}
