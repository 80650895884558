import React from 'react';
import DataTable from 'react-data-table-component';
import { DateTime } from 'luxon';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import { EditIcon, PlusIcon } from '../../components/Icons';

import Error from '../../components/Error';

const GET_BUDGETS = gql`
  query GetBudgetsByStationId($id: Int!) {
    getBudgetsByStationId(id: $id) {
      id
      station {
        id
        name
      }
      amount
      start_date
      end_date
      created
    }
  }
`;

export default function Budgets(props) {
  const budgets = useQuery(GET_BUDGETS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: props.id,
    },
  });
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  if (budgets.loading) return <h3>Loading...</h3>;

  const columns = [
    {
      name: 'Month',
      selector: (row) => row.start_date,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.start_date)).toUTC();

        return date.toFormat('MMMM yyyy');
      },
    },
    {
      name: 'Budget',
      selector: (row) => row.amount,
      sortable: true,
      format: (row) => {
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        return formatter.format(row.amount);
      },
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Link
          to={`/stations/${row.station.id}/budgets/${row.id}`}
          className="btn btn-link"
        >
          <EditIcon /> Edit
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12" style={{ textAlign: 'right' }}>
          <Link
            to={`/stations/${props.id}/budgets/add`}
            className="btn btn-outline-success btn-pill float-right"
            style={{ marginLeft: 'auto' }}
          >
            <PlusIcon /> Add Budget
          </Link>
        </div>
      </div>

      <div className="card mt-2 mb-1">
        <DataTable
          data={budgets.data.getBudgetsByStationId.sort((a, b) => {
            return parseInt(a.start_date) > parseInt(b.start_date) ? 1 : -1;
          })}
          columns={columns}
          striped
        />
      </div>
    </>
  );
}
