import { useState } from 'react';
import { useFormik } from 'formik';
import Message from '../../components/Message';
import { fetchWithToken } from '../../fetcher';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';

export default function StatementForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [selectedFile, setSelectedFile] = useState();

  let initialValues = {
    entity: '',
  };

  let validationSchema = yup.object({
    entity: yup
      .number('Entity must be selected')
      .required('Entity must be selected'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let statementId = null;

      if (selectedFile !== undefined) {
        const formData = new FormData();

        formData.append('file', selectedFile);
        formData.append('entity', values.entity);

        try {
          let response = await fetchWithToken('/uploadstatement', {
            method: 'POST',
            body: formData,
          });

          statementId = response.id;

          setSubmitting(true);

          if (props.isAdding) {
            props.handleSubmit(values, statementId);
          }
        } catch (err) {
          toast.error(
            'There was a problem processing the file. Please make sure it is an .xlsx file in the proper format!'
          );
        }
      }
    },
  });

  const { getFieldProps } = formik;

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the Statement is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the Statement is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  let enabledEntities = props.entities.data.getEntities
    .filter((x) => x.enabled)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.name}
        </option>
      );
    });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* File Chooser */}
        {props.isAdding && (
          <>
            <p>Click <b>Choose File</b> and select the Statement to upload. <b>Please note that file must be the original .xlsx file which is provided by FedEx</b>. Other formats are not supported!</p>

            <div className="form-group col-md-12 mb-3">
              <input type="file" accept=".xlsx" onChange={onFileChange} />
            </div>
          </>
        )}

        {/* Entity Chooser */}
        <div className="form-group col-md-6 mb-3">
          <label className="form-label">Entity</label>

          <select
            {...getFieldProps('entity')}
            className={`form-select ${
              formik.errors.entity && formik.touched.entity ? 'is-invalid' : ''
            }`}
            type="text"
          >
            <option value="">Choose an Entity</option>

            {/* Pull in all entities */}
            {enabledEntities}
          </select>

          {formik.errors.entity && formik.touched.entity ? (
            <span style={{ color: '#D53939' }}>{formik.errors.entity}</span>
          ) : null}
        </div>

        {/* Submit Button */}
        <button
          className="btn btn-primary"
          style={{ marginTop: '1em' }}
          type="submit"
        >
          Submit
        </button>
      </form>

      <ToastContainer
        autoClose={7500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </>
  );
}
