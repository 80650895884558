import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import VendorForm from './VendorForm.jsx';
import config from '../../config';

const GET_VENDOR = gql`
  query GetVendor($id: Int!) {
    getVendor(id: $id) {
      id
      enabled
      name
      created
    }
  }
`;

const ADD_VENDOR = gql`
  mutation CreateVendor($name: String!) {
    createVendor(vendor: { name: $name }) {
      id
      enabled
      name
      created
    }
  }
`;

const UPDATE_VENDOR = gql`
  mutation UpdateVendor($id: Int!, $enabled: Boolean, $name: String) {
    updateVendor(id: $id, args: { enabled: $enabled, name: $name }) {
      id
      enabled
      name
      created
    }
  }
`;

export default function AddEditVendor(props) {
  const [getVendor, vendor] = useLazyQuery(GET_VENDOR, {
    fetchPolicy: 'no-cache',
  });
  const { isTokenExpired, deleteToken } = useToken();

  let history = useHistory();

  let { id } = useParams();

  // Load Vendor details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      getVendor({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addVendor] = useMutation(ADD_VENDOR);
  const [updateVendor] = useMutation(UPDATE_VENDOR);

  function handleUpdateVendor(values) {
    let { enabled, name } = values;

    updateVendor({
      variables: {
        id: parseInt(id),
        enabled,
        name,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/vendors');
      }, config.saveDialogTimeout);
    });
  }

  function handleAddVendor(values) {
    let { name } = values;

    addVendor({
      variables: {
        name,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/vendors');
      }, 1000);
    });
  }

  return (
    <div>
      {props.isAdding && (
        <VendorForm isAdding={props.isAdding} handleSubmit={handleAddVendor} />
      )}

      {!props.isAdding && !vendor.loading && vendor.called && (
        <VendorForm
          isAdding={props.isAdding}
          handleSubmit={handleUpdateVendor}
          initialValues={vendor.data.getVendor}
        />
      )}

      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </div>
  );
}
