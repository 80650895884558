import PageHero from '../components/PageHero';
import Breadcrumbs from '../components/Breadcrumbs'

export default function PageWithHero(props) {
  return (
    <div>
      {/* Show page Title */}
      <PageHero title={props.title} subtitle={props.subTitle} />

      <div className="container">
        {/* If there are breadcrumbs, show them */}
        {props.breadcrumbs && <Breadcrumbs crumbs={props.breadcrumbs} />}

        {/* Set up the content section */}
        <div
          style={{ backgroundColor: 'white', marginTop: '1em', padding: '1em' }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
