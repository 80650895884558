import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { EditIcon, PlusIcon, StarIcon } from '../../components/Icons';
import SearchField from '../../components/SearchField';
import { Link } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import useFilter from '../../hooks/useFilter';
import Error from '../../components/Error';

const columns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    width: '50px',
  },
  {
    name: 'Name',
    cell: (row) => {
      let isAdmin =
        row.permissions.filter((x) => x.key === 'isadmin').length > 0;

      return (
        <div>
          {isAdmin && <StarIcon color="#FFE089" />} {row.first_name}{' '}
          {row.last_name}
        </div>
      );
    },
  },
  {
    name: 'Email',
    selector: (row) => row.email,
    sortable: true,
    grow: 2,
  },
  {
    name: 'EE Number',
    selector: (row) => row.ee_number,
    sortable: true,
  },
  {
    name: 'Is Active',
    selector: (row) => row.enabled,
    sortable: true,
    cell: (row) => {
      return (
        <div>
          <span
            className={`badge ${row.enabled ? 'bg-success' : 'bg-danger'} me-1`}
          />
          {row.enabled ? 'Yes' : 'No'}
        </div>
      );
    },
  },
  {
    name: 'Is Driver',
    cell: (row) => {
      let isDriver =
        row.permissions.filter((x) => x.key === 'isdriver').length > 0;

      return (
        <div>
          <span
            className={`badge ${isDriver ? 'bg-success' : 'bg-danger'} me-1`}
          />
          {isDriver ? 'Yes' : 'No'}
        </div>
      );
    },
  },
  {
    name: 'Actions',
    cell: (row) => (
      <Link to={`/users/${row.id}`} className="btn btn-link">
        <EditIcon /> Edit
      </Link>
    ),
  },
];

const GET_USERS = gql`
  query GetUsers {
    getUsers {
      id
      enabled
      first_name
      last_name
      email
      password
      ee_number
      permissions {
        id
        key
        category
        name
        description
      }
    }
  }
`;

export default function Users() {
  const { loading, data } = useQuery(GET_USERS, {
    fetchPolicy: 'no-cache',
  });
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [activeOrInactiveFilter, setActiveOrInactiveFilter] = useState();
  const { isTokenExpired, deleteToken, hasPermission } = useToken();
  const { filter } = useFilter();

  const handleFilterChange = (e) => {
    switch (e.target.name) {
      case 'activeOrInactive':
        setActiveOrInactiveFilter(e.target.value);
        break;
      default:
        break;
    }
  };

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (data) {
      let filteredData = filter(data.getUsers, searchValue, [
        'first_name',
        'last_name',
        'email',
        'ee_number',
      ]);

      // Handle active or inactive
      switch (activeOrInactiveFilter) {
        case 'active':
          filteredData = filteredData.filter((x) => x.enabled);
          break;
        case 'inactive':
          filteredData = filteredData.filter((x) => !x.enabled);
          break;
        default:
          // Do nothing
          break;
      }

      setFilteredData(filteredData);
    }
  }, [searchValue, data, activeOrInactiveFilter]);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  return (
    <>
      {!loading && (
        <>
          <div className="row">
            <div className="col-md-6">
              <SearchField value={searchValue} setValue={setSearchValue} />
            </div>

            {/* Active / Inactive Filter */}
            <div className="col-md-3">
              <select
                name="activeOrInactive"
                className="form-select"
                type="text"
                onChange={handleFilterChange}
              >
                <option value="">All Users</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>

            <div className="col-md-3" style={{ textAlign: 'right' }}>
              <Link
                to="/users/add"
                className="btn btn-outline-success btn-pill float-right"
                style={{ marginLeft: 'auto' }}
              >
                <PlusIcon /> Add User
              </Link>
            </div>
          </div>

          <div className="card mt-2 mb-1">
            <DataTable
              className="table card-table"
              data={filteredData}
              columns={columns}
              pagination
              striped
            />
          </div>
        </>
      )}
    </>
  );
}
