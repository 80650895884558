import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import DataTable from 'react-data-table-component';
import * as yup from 'yup';
import Message from '../../components/Message';
import useToken from '../../hooks/useToken';
import { fetchWithToken } from '../../fetcher';

const deliveriesToShow = 5;

const GET_DELIVERIES = gql`
  query GetDeliveries($limit: Int, $driver: Int) {
    getDeliveries(limit: $limit, driver: $driver) {
      id
      enabled
      driver {
        id
        first_name
        last_name
        ee_number
      }
      truck {
        id
        truck_number
        station {
          name
        }
        entity {
          name
        }
      }
      notes {
        id
        note
        user {
          id
          first_name
          last_name
        }
        created
      }
      images {
        id
        created
      }
      delivery_date
      trip_type {
        key
        name
      }
      avr
      linehaul_from
      linehaul_to
      bobtail
      created
    }
  }
`;

const columns = [
  {
    name: 'Delivery Date',
    selector: (row) => row.delivery_date,
    sortable: true,
    format: (row, index) => {
      // Parse UTC value and convert to EDT
      let date = DateTime.fromMillis(parseInt(row.delivery_date)).toUTC();

      return date.toFormat('MM/dd/yyyy');
    },
    width: '120px',
  },
  {
    name: 'Driver',
    selector: (row) => row.driver,
    format: (row, index) => {
      return `${row.driver.first_name} ${row.driver.last_name}`;
    },
    grow: 2,
  },
  {
    name: 'Truck',
    selector: (row) => row.truck.truck_number,
    sortable: true,
  },
  {
    name: 'Trip Type',
    selector: (row) => row.trip_type.name,
  },
  {
    name: 'AVR',
    selector: (row) => row.avr,
    sortable: true,
  },
  {
    name: 'From',
    selector: (row) => row.linehaul_from,
    sortable: true,
  },
  {
    name: 'To',
    selector: (row) => row.linehaul_to,
    sortable: true,
  },
  {
    name: 'Notes',
    cell: (row) => {
      let firstNote = null;

      if (row.notes.length > 0) {
        firstNote = row.notes[0].note;
      }

      return <div>{firstNote}</div>;
    },
    grow: 2,
  },
];

export default function DeliveryForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [selectedFile, setSelectedFile] = useState();
  let { hasPermission, getUserId } = useToken();

  const [driver, setDriver] = useState(getUserId());

  const [getDeliveries, deliveries] = useLazyQuery(GET_DELIVERIES, {
    fetchPolicy: 'no-cache',
  });

  let enabledDriverList = props.drivers.filter((x) => {
    let show = true;

    if (!x.enabled) show = false;

    // If the user is NOT an admin, hide all other drivers
    if (!hasPermission('isadmin')) {
      if (x.id !== getUserId()) {
        show = false;
      }
    }

    return show;
  });

  useEffect(() => {
    let matchCurrentDriver = enabledDriverList.filter(
      (x) => x.id === parseInt(driver)
    );

    let loadTruckAndDeliveries =
      driver !== undefined && matchCurrentDriver.length > 0;

    if (loadTruckAndDeliveries) {
      getDeliveries({
        variables: {
          driver: parseInt(driver),
          limit: deliveriesToShow,
        },
      });
    } else {
      deliveries.data = null;
    }
  }, [driver]);

  useEffect(() => {
    try {
      let recentDeliveries = deliveries.data.getDeliveries;

      recentDeliveries.sort((a, b) => b.id - a.id);

      let { truck } = recentDeliveries[0];

      formik.setFieldValue('truck', truck.id);
    } catch (err) {}
  }, [deliveries]);

  let initialValues = {
    deliveryDate: '',
    driver: '',
    truck: '',
    tripType: '',
    avr: '',
    linehaulFrom: '',
    linehaulTo: '',
    note: '',
    bobtail: 0
  };

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      if (props.initialValues.delivery_date) {
        if (!props.isAdding) {
          let deliveryDate = DateTime.fromMillis(
            parseInt(props.initialValues.delivery_date),
            {
              zone: 'utc',
            }
          ).toFormat('yyyy-MM-dd');

          initialValues.deliveryDate = deliveryDate;
        } else {
          initialValues.deliveryDate = props.initialValues.delivery_date;
        }
      }

      if (props.initialValues.driver) {
        initialValues.driver = props.initialValues.driver.id;
      }

      if (props.initialValues.truck) {
        initialValues.truck = props.initialValues.truck.id;
      }

      if (props.initialValues.trip_type) {
        initialValues.tripType = props.initialValues.trip_type.id;
      }

      if (props.initialValues.avr) {
        initialValues.avr = props.initialValues.avr;
      }

      if (props.initialValues.linehaul_from) {
        initialValues.linehaulFrom = props.initialValues.linehaul_from;
      }

      if (props.initialValues.linehaul_to) {
        initialValues.linehaulTo = props.initialValues.linehaul_to;
      }

      if(props.initialValues.bobtail) {
        initialValues.bobtail = props.initialValues.bobtail;
      }

      if (props.initialValues.notes) {
        if (props.initialValues.notes.length > 0) {
          initialValues.note = props.initialValues.notes[0].note;
        }
      }
    }
  }

  // If we're editing and the current date is in a locked payroll cycle,
  // disable the date picker
  let canEditDeliveryDate = false;

  if (!props.isAdding) {
    let thisPayrollCycle = props.payrollCycles.find((cycle) => {
      let deliveryDate = DateTime.fromMillis(
        parseInt(props.initialValues.delivery_date),
        {
          zone: 'utc',
        }
      );

      const weekStart = parseInt(cycle.week_start);
      const weekEnd = parseInt(cycle.week_end);

      return deliveryDate >= weekStart && deliveryDate <= weekEnd;
    });

    try {
      if (thisPayrollCycle?.lock === 1) {
        canEditDeliveryDate = false;
      } else {
        canEditDeliveryDate = true;
      }
    } catch (err) {}
  } else {
    canEditDeliveryDate = true;
  }

  // Find min and max dates from Payroll Cycles
  let minDate = null;
  let maxDate = null;

  let openPayrollCycles = props.payrollCycles
    .filter((x) => x.enabled === 1 && x.lock === 0)
    .sort((a, b) => a.week_start - b.week_start);

  let minDateString = openPayrollCycles[0].week_start;
  let maxDateString = openPayrollCycles[openPayrollCycles.length - 1].week_end;

  minDate = DateTime.fromMillis(parseInt(minDateString))
    .toUTC()
    .toFormat('yyyy-MM-dd');
  maxDate = DateTime.fromMillis(parseInt(maxDateString)).toFormat('yyyy-MM-dd');

  let validationSchema = yup.object({
    driver: yup
      .number('Driver must be selected')
      .required('Driver must be selected'),
    truck: yup
      .number('Truck must be selected')
      .required('Truck must be selected'),
    tripType: yup
      .number('Trip Type must be selected')
      .required('Trip Type must be selected'),
    avr: yup.number().when('tripType', {
      is: 1,
      then: yup
        .number('AVR is required')
        .required('AVR is required')
        .typeError('AVR must be a number'),
    }),
    linehaulFrom: yup.number().when('tripType', {
      is: 2,
      then: yup
        .number('From is required')
        .required('From is required')
        .typeError('From must be a number'),
    }),
    linehaulTo: yup.number().when('tripType', {
      is: 2,
      then: yup
        .number('To is required')
        .required('To is required')
        .typeError('To must be a number'),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let imageId = null;

      if (selectedFile !== undefined) {
        const formData = new FormData();

        formData.append('file', selectedFile);

        let response = await fetchWithToken('/upload', {
          method: 'POST',
          body: formData,
        });

        imageId = response.id;
      }

      setSubmitting(true);

      if (props.isAdding) {
        props.handleSubmit(values, imageId);
      } else {
        props.handleSubmit(props.initialValues, values);
      }
    },
  });

  const handleDelete = (id) => {
    setSubmitting(true);
    props.handleDelete(id);
  };

  const { getFieldProps } = formik;

  let enabledDrivers = enabledDriverList
    .sort((a, b) => {
      return a.first_name.localeCompare(b.first_name);
    })
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.first_name} {x.last_name}
        </option>
      );
    });

  // Check to see if current user shows up in list

  let enabledTrucks = props.trucks
    .filter((x) => x.enabled)
    .sort((a, b) => {
      return a.truck_number - b.truck_number;
    })
    .map((x) => {
      return (
        <option key={x.id} value={x.id}>
          {x.truck_number}
        </option>
      );
    });

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the Delivery is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the Delivery is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleDriverChange = (e) => {
    setDriver(e.target.value);

    formik.handleChange(e);
  };

  return (
    <>
      <p
        style={{
          fontSize: '16px',
          fontWeight: 'bold',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: 'white',
          backgroundColor: '#31B245',
          padding: '.5rem',
        }}
      >
        We take safe equipment safely to its destination!
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* Delivery Date */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Delivery Date</label>

            <input
              className="form-control"
              type="date"
              {...getFieldProps('deliveryDate')}
              disabled={!canEditDeliveryDate}
              min={minDate}
              max={maxDate}
            />

            {formik.errors.date && formik.touched.date ? (
              <span style={{ color: '#D53939' }}>{formik.errors.date}</span>
            ) : null}
          </div>

          {/* Driver */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Driver</label>

            <select
              {...getFieldProps('driver')}
              onChange={handleDriverChange}
              className={`form-select ${
                formik.errors.driver && formik.touched.driver
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
            >
              <option value="">Choose a Driver</option>

              {/* Pull in all other drivers */}
              {enabledDrivers}
            </select>

            {formik.errors.driver && formik.touched.driver ? (
              <span style={{ color: '#D53939' }}>{formik.errors.driver}</span>
            ) : null}
          </div>

          {/* Truck */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Truck</label>

            <select
              {...getFieldProps('truck')}
              className={`form-select ${
                formik.errors.truck && formik.touched.truck ? 'is-invalid' : ''
              }`}
              type="text"
            >
              <option value="">Choose a Truck</option>

              {/* Pull in all other trucks */}
              {enabledTrucks}
            </select>

            {formik.errors.truck && formik.touched.truck ? (
              <span style={{ color: '#D53939' }}>{formik.errors.truck}</span>
            ) : null}
          </div>

          {/* Trip Type */}
          <div className="form-group col-md-3 mb-3">
            <label className="form-label">Trip Type</label>

            {props.showTripTypeCitySpot && (
              <label className="form-check form-check-inline">
                <input
                  {...getFieldProps('tripType')}
                  className="form-check-input"
                  type="radio"
                  defaultChecked={initialValues.tripType == 1}
                  value="1"
                />
                <span className="form-check-label">City Spot</span>
              </label>
            )}

            {props.showTripTypeLineHaul && (
              <label className="form-check form-check-inline">
                <input
                  {...getFieldProps('tripType')}
                  className="form-check-input"
                  type="radio"
                  defaultChecked={initialValues.tripType == 2}
                  value="2"
                />

                <span className="form-check-label">Line Haul</span>
              </label>
            )}

            {formik.errors.tripType && formik.touched.tripType ? (
              <span style={{ color: '#D53939', display: 'block' }}>
                {formik.errors.tripType}
              </span>
            ) : null}
          </div>

          {/* Bobtail */}
          {formik.values.tripType == 2 && (
            <div className="form-group col-md-3 mb-3">
            <label className="form-label">Is this a Bobtail run?</label>

              <label className="form-check form-check-inline">
                <input
                  {...getFieldProps('bobtail')}
                  className="form-check-input"
                  type="radio"
                  defaultChecked={initialValues.bobtail == 0}
                  value={0}
                />
                <span className="form-check-label">No</span>
              </label>

              <label className="form-check form-check-inline">
                <input
                  {...getFieldProps('bobtail')}
                  className="form-check-input"
                  type="radio"
                  defaultChecked={initialValues.bobtail == 1}
                  value={1}
                />

                <span className="form-check-label">Yes</span>
              </label>
          </div>
          )}

          {/* AVR */}
          {formik.values.tripType == 1 && (
            <div className="form-group col-md-6 mb-3">
              <label className="form-label">AVR</label>

              <input
                {...getFieldProps('avr')}
                className={`form-control ${
                  formik.errors.avr && formik.touched.avr ? 'is-invalid' : ''
                }`}
                type="text"
                placeholder="Enter AVR"
                maxLength="4"
              />

              {formik.errors.avr && formik.touched.avr ? (
                <span style={{ color: '#D53939' }}>{formik.errors.avr}</span>
              ) : null}
            </div>
          )}

          {/* Line Haul From */}
          {formik.values.tripType == 2 && (
            <div className="form-group col-md-5 mb-3">
              <label className="form-label">From</label>

              <input
                {...getFieldProps('linehaulFrom')}
                className={`form-control ${
                  formik.errors.linehaulFrom && formik.touched.linehaulFrom
                    ? 'is-invalid'
                    : ''
                }`}
                type="text"
                placeholder="Enter From"
                maxLength="5"
              />

              {formik.errors.linehaulFrom && formik.touched.linehaulFrom ? (
                <span style={{ color: '#D53939' }}>
                  {formik.errors.linehaulFrom}
                </span>
              ) : null}
            </div>
          )}

          {/* Line Haul To */}
          {formik.values.tripType == 2 && (
            <div className="form-group col-md-5 mb-3">
              <label className="form-label">To</label>

              <input
                {...getFieldProps('linehaulTo')}
                className={`form-control ${
                  formik.errors.linehaulTo && formik.touched.linehaulTo
                    ? 'is-invalid'
                    : ''
                }`}
                type="text"
                placeholder="Enter To"
                maxLength="5"
              />

              {formik.errors.linehaulTo && formik.touched.linehaulTo ? (
                <span style={{ color: '#D53939' }}>
                  {formik.errors.linehaulTo}
                </span>
              ) : null}
            </div>
          )}
        </div>

        {/* Note */}
        <div className="form-group col-md-12 mb-3">
          <label className="form-label">Note</label>

          <textarea
            {...getFieldProps('note')}
            className={`form-control ${
              formik.errors.note && formik.touched.note ? 'is-invalid' : ''
            }`}
            rows="6"
            placeholder="Add notes if needed"
          />

          {formik.errors.note && formik.touched.note ? (
            <span style={{ color: '#D53939' }}>{formik.errors.note}</span>
          ) : null}
        </div>

        {/* Image Chooser */}

        {props.isAdding && (
          <div className="form-group col-md-12 mb-3">
            <input type="file" onChange={onFileChange} />
          </div>
        )}

        {/* Submit Button */}
        <button
          className="btn btn-primary"
          style={{ marginTop: '1em' }}
          type="submit"
        >
          Submit
        </button>

        {/* Delete Button */}
        {!props.isAdding && (
          <button
            className="btn btn-danger"
            style={{ marginTop: '1em', marginLeft: '1em' }}
            onClick={() => handleDelete(props.deliveryId)}
            type="button"
          >
            Delete
          </button>
        )}
      </form>

      <div className="hr-text">Recent Deliveries (max {deliveriesToShow})</div>

      {deliveries.data && driver !== undefined && (
        <DataTable
          className="table card-table"
          data={deliveries.data.getDeliveries.sort((a, b) => b.id - a.id)}
          columns={columns}
          striped
        />
      )}
    </>
  );
}
