const getFakePosts = () => {
  return [{
    'id': 5,
    'enabled': false,
    'category': {
      'id': 1,
      'name': 'Giving Tuesday',
      'description': null,
      '__typename': 'Category',
    },
    'title': 'Test',
    'body': 'test',
    'created_by': {
      'id': 1,
      'first_name': 'Erick',
      'last_name': 'Pece',
      '__typename': 'User',
    },
    'images': [],
    'created': '1638023709000',
    '__typename': 'Post',
  }];
};

const getFakeFuelLocations = () => {
  return [{
    'id': 1,
    'siteNumber': 1,
    'abbreviation': 'TEST',
    'type': 'HUB',
    'location': 'Test Location',
    'defPrice': 2.889,
    'dieselLastWeek': 3.945,
    'dieselThisWeek': 3.397,
    'change': -0.550,
    'created': '2024-07-27T18:16:31Z',
  },
    {
      'id': 2,
      'siteNumber': 1,
      'abbreviation': 'TEST 2',
      'type': 'HUB',
      'location': 'Test Location 2',
      'defPrice': 2.889,
      'dieselLastWeek': 3.945,
      'dieselThisWeek': 3.397,
      'change': -0.550,
      'created': '2024-07-27T18:16:31Z',
    }];
};

const getFakeFuelLocationDates = () => {
  return {"id":1,"startDate":"2022-07-09T00:00:00","endDate":"2022-07-15T00:00:00"};
}

export {
  getFakePosts,
  getFakeFuelLocations,
  getFakeFuelLocationDates
};