import { useHistory } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';

import FuelLocationsForm from './FuelLocationsForm';

export default function AddEditStatement(props) {
  const { isTokenExpired, deleteToken } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  let history = useHistory();

  async function handleAddFuelLocations(err) {
    if (err) {
      let description = 'There was a problem uploading this PDF';

      alert(description);

      history.push('/fuellocations');
    } else {
      setTimeout(() => {
        history.push('/fuellocations');
      }, 1000);
    }
  }

  return (
    <>
      <FuelLocationsForm handleSubmit={handleAddFuelLocations} />
    </>
  );
}
