import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';

import StatementForm from './StatementForm';

const GET_ENTITIES = gql`
  query {
    getEntities {
      id
      enabled
      name
    }
  }
`;

export default function AddEditStatement(props) {
  const { isTokenExpired, deleteToken } = useToken();
  const entities = useQuery(GET_ENTITIES);

  if (isTokenExpired()) {
    deleteToken();
  }

  let history = useHistory();

  async function handleAddStatement(values, imageId) {
    try {
      setTimeout(() => {
        history.push('/statements');
      }, 1000);
    } catch (err) {
      let description = 'There was a problem adding this Statement';

      return <Error description={description} />;
    }
  }

  return (
    <>
      {!entities.loading && entities.called && (
        <StatementForm
          isAdding={props.isAdding}
          handleSubmit={handleAddStatement}
          entities={entities}
        />
      )}
    </>
  );
}
