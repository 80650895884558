import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { fetchWithToken } from '../../fetcher';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';

const columns = [
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Description',
    selector: (row) => row.description,
    sortable: true,
  },
  {
    name: 'Actions',

    cell: (row) => (
      <div>
        <Link to={`/reports/tabular/${row.id}`}>
          <span className="icon-text">
            <span className="icon">
              <i className="fas fa-file"></i>
            </span>
            <span>View</span>
          </span>
        </Link>
      </div>
    ),
  },
];

export default function TabularReports() {
  const [reports, setReports] = useState();
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    return fetchWithToken('/reports', {
      method: 'GET',
    }).then((data) => {
      const sortedReports = data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      setReports(sortedReports);
    });
  }, []);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  return (
    <>
      <DataTable data={reports} columns={columns} pagination />
    </>
  );
}
