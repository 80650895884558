export default function Message(props) {
  return (
    <div className="flex-fill d-flex align-items-center justify-content-center">
      <div className="container-tight py-6">
        <div className="empty">
          <div className="empty-header">{props.title}</div>
          <p className="empty-title">{props.subtitle}</p>
          <p className="empty-subtitle text-muted">{props.description}</p>
          {props.showProgress && (
            <div className="progress">
              <div className="progress-bar progress-bar-indeterminate bg-green"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
