import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Message from '../../components/Message';
import Budgets from './Budgets';

export default function StationForm(props) {
  let [submitting, setSubmitting] = useState(false);

  let initialValues = {
    name: '',
    enabled: '',
    notification_emails: '',
  };

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      initialValues.name = props.initialValues.name;
      initialValues.enabled = props.initialValues.enabled;
      initialValues.notification_emails =
        props.initialValues.notification_emails;
    }
  }

  let validationSchema = yup.object({
    name: yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      props.handleSubmit(values);
    },
  });

  const { getFieldProps } = formik;

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the Station is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the Station is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        {/* Name */}
        <div className="form-group col-md-12 mb-3">
          <label className="form-label">Name</label>

          <input
            {...getFieldProps('name')}
            className={`form-control ${
              formik.errors.name && formik.touched.name ? 'is-invalid' : ''
            }`}
            type="text"
            placeholder="Enter Station Name"
            disabled={!props.isAdding}
            autoFocus
          />

          {formik.errors.name && formik.touched.name ? (
            <span style={{ color: '#D53939' }}>{formik.errors.name}</span>
          ) : null}
        </div>

        {/* Notification Emails */}
        <div className="form-group col-md-12 mb-3">
          <label className="form-label">Notification Emails</label>
          <p>
            Provide a comma-separated list of email addresses to notify for
            Station related emails.
          </p>

          <textarea
            {...getFieldProps('notification_emails')}
            className={`form-control ${
              formik.errors.notification_emails &&
              formik.touched.notification_emails
                ? 'is-invalid'
                : ''
            }`}
            type="text"
            placeholder="Enter Notification Emails"
          />

          {formik.errors.notification_emails &&
          formik.touched.notification_emails ? (
            <span style={{ color: '#D53939' }}>
              {formik.errors.notification_emails}
            </span>
          ) : null}
        </div>
      </div>

      {/* Options */}
      {!props.isAdding && (
        <>
          <div className="form-group col-md-12 mb-3">
            <label className="form-label">Options</label>

            {/* Is This Station Active? */}

            <label className="form-check mb-2">
              <input
                {...getFieldProps('enabled')}
                className="form-check-input"
                type="checkbox"
                defaultChecked={initialValues.enabled}
              />
              <span className="form-check-label">Is This Station Active?</span>
            </label>
          </div>

          <div className="form-group col-md-12 mb-3">
            <label className="form-label">Budgets</label>

            <Budgets id={props.initialValues.id} />
          </div>
        </>
      )}

      {/* Submit Button */}
      <button
        className="btn btn-primary"
        style={{ marginTop: '1em' }}
        type="submit"
      >
        Submit
      </button>
    </form>
  );
}
