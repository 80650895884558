import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import config from '../../config';
import Message from '../../components/Message';

import Error from '../../components/Error';

const GET_STATEMENT = gql`
  query GetStatement($id: Int!) {
    getStatement(id: $id) {
      id
      enabled
      first_delivery
      last_delivery
      linehaul_records
      spot_records
      status {
        id
        name
      }
      linehaul_trips {
        id
        enabled
        row_id
        date
        tripNumber
        legOrg
        legDest
        dAndH
        status {
          id
          name
        }
      }
      spot_trips {
        id
        enabled
        row_id
        date
      }
      status {
        id
        name
      }
      created
    }
  }
`;

const DELETE_STATEMENT = gql`
  mutation DeleteStatement($id: Int!) {
    deleteStatement(id: $id)
  }
`;

export default function ViewStatement() {
  let { id } = useParams();

  let [submitting, setSubmitting] = useState(false);
  let [linehaulMissingDandH, setLinehaulMissingDandH] = useState([]);

  const statement = useQuery(GET_STATEMENT, {
    fetchPolicy: 'no-cache',
    variables: {
      id: parseInt(id),
    },
  });

  useEffect(() => {
    if (statement.data) {
      let { linehaul_trips } = statement.data.getStatement;

      let missingDAndH = linehaul_trips.filter((x) => x.dAndH === 0);

      setLinehaulMissingDandH(missingDAndH);
    }
  }, [statement]);

  const [deleteStatement] = useMutation(DELETE_STATEMENT);

  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  let history = useHistory();

  if (isTokenExpired()) {
    deleteToken();
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  if (statement.loading) return <h3>Loading...</h3>;

  async function handleDeleteStatement(id) {
    setSubmitting(true);

    deleteStatement({
      variables: {
        id: parseInt(id),
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/statements');
      }, config.saveDialogTimeout);
    });
  }

  if (submitting) {
    let title = 'Deleting';
    let description = 'Please wait while the Statement is being deleted';

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.enabled,
      sortable: true,
      cell: (row) => {
        let badgeColor = 'bg-success';

        switch (row.status.id) {
          case 1:
            badgeColor = 'bg-warning';
            break;
          case 2:
            badgeColor = 'bg-warning';
            break;
          case 3:
            badgeColor = 'bg-success';
            break;
          case 4:
            badgeColor = 'bg-danger';
            break;
          default:
            badgeColor = 'bg-success';
            break;
        }

        return (
          <div>
            <span className={`badge ${badgeColor} me-1`} />
            {row.status.name}
          </div>
        );
      },
    },
    {
      name: 'D&H',
      selector: (row) => row.dAndH,
      sortable: true,
    },
    {
      name: 'From',
      selector: (row) => row.legOrg,
      sortable: true,
    },
    {
      name: 'To',
      selector: (row) => row.legDest,
      sortable: true,
    },
    {
      name: 'Delivery Date',
      selector: (row) => row.date,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.date)).toUTC();

        return date.toFormat('MM/dd/yyyy');
      },
      width: '140px',
    },
  ];

  return (
    <>
      {statement.data !== undefined && (
        <>
          <div className="h1 mb-1">
            Statement #{statement.data.getStatement.id}
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">Linehaul Trips</div>
                  </div>
                  <div className="h1 mb-0">
                    {statement.data.getStatement.linehaul_records}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">Spot Trips</div>
                  </div>
                  <div className="h1 mb-0">
                    {statement.data.getStatement.spot_records}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">First Delivery</div>
                  </div>
                  <div className="h1 mb-0">
                    {DateTime.fromMillis(
                      parseInt(statement.data.getStatement.first_delivery)
                    )
                      .toUTC()
                      .toFormat('MM/dd/yyyy')}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="subheader">Last Delivery</div>
                  </div>
                  <div className="h1 mb-0">
                    {DateTime.fromMillis(
                      parseInt(statement.data.getStatement.last_delivery)
                    )
                      .toUTC()
                      .toFormat('MM/dd/yyyy')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* TODO: Missing D&H */}
          <div className="card mt-2 mb-1">
            <DataTable
              data={linehaulMissingDandH}
              columns={columns}
              // pagination
              striped
            />
          </div>

          <button
            className="btn btn-danger mt-3"
            onClick={() =>
              handleDeleteStatement(statement.data.getStatement.id)
            }
            type="button"
          >
            Delete
          </button>
        </>
      )}
    </>
  );
}
