import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import config from '../../config';
import { DateTime } from 'luxon';

const GET_POST = gql`
  query GetPost($id: Int!) {
    getPost(id: $id) {
      id
      enabled
      category {
        name
      }
      created_by {
        id
        first_name
        last_name
      }
      images {
        id
      }
      title
      body
      created
    }
  }
`;

export default function ViewPost(props) {
  const [getPost, post] = useLazyQuery(GET_POST, {
    fetchPolicy: 'no-cache',
  });

  const { isTokenExpired, deleteToken } = useToken();

  let { id } = useParams();

  // Load Post details
  useEffect(() => {
    if (id !== undefined) {
      getPost({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  if (post.loading || !post.called) {
    return (
      <h4 style={{ padding: '1em', marginBottom: '0px' }}>
        Loading<span className="animated-dots"></span>
      </h4>
    );
  }

  const { title, body, created, images } = post.data.getPost;
  const { first_name, last_name } = post.data.getPost.created_by;
  const category = post.data.getPost.category.name;

  let imageUrl = null;

  if (images.length > 0) {
    imageUrl = `${config.apiRootUrl}/file/${images[0].id}`;
  }

  // Parse UTC value and convert to EDT
  let date = DateTime.fromMillis(parseInt(created)).toUTC();

  return (
    <div>
      <h2>{title}</h2>
      <p className="text-muted" style={{ fontSize: '.8em' }}>
        <em>Posted in {category}</em>
      </p>
      <h5>
        By {first_name} {last_name} on {date.toFormat('MM/dd/yyyy')}
      </h5>
      <p style={{ whiteSpace: 'pre-wrap' }}>{body}</p>

      {imageUrl !== null && (
        <center>
          <img alt="Post" className="post-image" src={`${imageUrl}`}></img>
        </center>
      )}
    </div>
  );
}
