import React from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumbs(props) {
  let crumbs = props.crumbs.map((x, index) => {
    if (!x.active) {
      return (
        <li className="breadcrumb-item" key={index}>
          <Link to={x.url}>{x.name}</Link>
        </li>
      );
    } else {
      return (
        <li className="breadcrumb-item active" key={index}>
          <a href="#" aria-current="page">
            {x.name}
          </a>
        </li>
      );
    }
  });

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ol className="breadcrumb breadcrumb-alternate">{crumbs}</ol>
    </nav>
  );
}
