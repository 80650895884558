import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import EntityForm from './EntityForm';
import config from '../../config';
import Error from '../../components/Error';

const GET_ENTITY = gql`
  query GetEntity($id: Int!) {
    getEntity(id: $id) {
      id
      enabled
      name
      created
    }
  }
`;

const ADD_ENTITY = gql`
  mutation CreateEntity($name: String!) {
    createEntity(entity: { name: $name }) {
      id
      enabled
      name
      created
    }
  }
`;

const UPDATE_ENTITY = gql`
  mutation UpdateEntity($id: Int!, $enabled: Boolean, $name: String) {
    updateEntity(id: $id, args: { enabled: $enabled, name: $name }) {
      id
      enabled
      name
      created
    }
  }
`;

export default function AddEditEntity(props) {
  const [getEntity, entity] = useLazyQuery(GET_ENTITY, {
    fetchPolicy: 'no-cache',
  });
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  let history = useHistory();

  let { id } = useParams();

  // Load Entity details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      getEntity({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addEntity] = useMutation(ADD_ENTITY);
  const [updateEntity] = useMutation(UPDATE_ENTITY);

  function handleUpdateEntity(values) {
    let { enabled, name } = values;

    updateEntity({
      variables: {
        id: parseInt(id),
        enabled,
        name,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/entities');
      }, config.saveDialogTimeout);
    });
  }

  function handleAddEntity(values) {
    let { name } = values;

    addEntity({
      variables: {
        name,
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/entities');
      }, 1000);
    });
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  return (
    <div>
      {props.isAdding && (
        <EntityForm
          isAdding={props.isAdding}
          handleSubmit={handleAddEntity}
        />
      )}

      {!props.isAdding && !entity.loading && entity.called && (
        <EntityForm
          isAdding={props.isAdding}
          handleSubmit={handleUpdateEntity}
          initialValues={entity.data.getEntity}
        />
      )}

      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </div>
  );
}
