import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import BudgetForm from './BudgetForm.jsx';
import config from '../../config';
import Error from '../../components/Error';
import { DateTime } from 'luxon';

const GET_BUDGET = gql`
  query GetBudget($id: Int!) {
    getBudget(id: $id) {
      id
      station {
        id
        name
      }
      amount
      start_date
      end_date
      created
    }
  }
`;

const GET_BUDGETS = gql`
  query GetBudgetsByStationId($id: Int!) {
    getBudgetsByStationId(id: $id) {
      id
      station {
        id
        name
      }
      amount
      start_date
      end_date
      created
    }
  }
`;

const ADD_BUDGET = gql`
  mutation CreateBudget(
    $station_id: Int!
    $amount: Int!
    $start_date: String!
    $end_date: String!
  ) {
    createBudget(
      budget: {
        station_id: $station_id
        amount: $amount
        start_date: $start_date
        end_date: $end_date
      }
    ) {
      id
      station {
        id
        name
      }
      amount
      start_date
      end_date
      created
    }
  }
`;

const UPDATE_BUDGET = gql`
  mutation UpdateBudget($id: Int!, $station_id: Int!, $amount: Int!) {
    updateBudget(id: $id, args: { station_id: $station_id, amount: $amount }) {
      id
      station {
        id
        name
      }
      amount
      start_date
      end_date
      created
    }
  }
`;

export default function AddEditBudget(props) {
  const [getBudget, budget] = useLazyQuery(GET_BUDGET, {
    fetchPolicy: 'no-cache',
  });
  const [getBudgets, budgets] = useLazyQuery(GET_BUDGETS, {
    fetchPolicy: 'no-cache',
  });
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  let history = useHistory();

  let { id: stationId, budgetId } = useParams();

  // Load Budget details if we're editing
  useEffect(() => {
    if (budgetId !== undefined) {
      getBudget({
        variables: {
          id: parseInt(budgetId),
        },
      });
    }
  }, [budgetId]);

  useEffect(() => {
    if (stationId !== undefined) {
      getBudgets({
        variables: {
          id: parseInt(stationId),
        },
      });
    }
  }, [stationId]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addBudget] = useMutation(ADD_BUDGET);
  const [updateBudget] = useMutation(UPDATE_BUDGET);

  function handleUpdateBudget(values) {
    updateBudget({
      variables: {
        id: parseInt(budgetId),
        station_id: parseInt(stationId),
        amount: parseFloat(values.amount),
      },
    }).then(() => {
      setTimeout(() => {
        history.push(`/stations/${stationId}`);
      }, config.saveDialogTimeout);
    });
  }

  function handleAddBudget(values) {
    let startDate = DateTime.fromMillis(parseInt(values.month));
    let endDate = startDate.endOf('month');

    addBudget({
      variables: {
        station_id: parseInt(values.station_id),
        amount: parseFloat(values.amount),
        start_date: startDate,
        end_date: endDate,
      },
    }).then(() => {
      setTimeout(() => {
        history.push(`/stations/${stationId}`);
      }, 1000);
    });
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  let initialValues = {
    station_id: stationId,
  };

  return (
    <div>
      {props.isAdding && (
        <BudgetForm
          isAdding={props.isAdding}
          handleSubmit={handleAddBudget}
          initialValues={initialValues}
          budgets={budgets}
        />
      )}

      {!props.isAdding && !budget.loading && budget.called && (
        <BudgetForm
          isAdding={props.isAdding}
          handleSubmit={handleUpdateBudget}
          initialValues={budget.data.getBudget}
        />
      )}

      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
    </div>
  );
}
