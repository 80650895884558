import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetchWithToken } from '../../fetcher';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';
import { FileDownloadIcon } from '../../components/Icons';
import { parse } from 'json2csv';
import { gql, useQuery } from '@apollo/client';

const GET_USERS = gql`
  {
    getUsers {
      id
      email
      enabled
      password
      first_name
      last_name
      ee_number
      created
      permissions {
        id
        key
        name
        description
      }
    }
  }
`;

export default function TabularReport(props) {
  const users = useQuery(GET_USERS, {
    fetchPolicy: 'no-cache',
  });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  let { id } = useParams();

  useEffect(() => {
    if (id == 2) {
      setName('Users');
      setDescription('Lists users, active and inactive');

      if (users.data !== undefined) {
        let reportData = users.data.getUsers.map((x) => {
          let created = DateTime.fromMillis(parseInt(x.created));

          return {
            Username: x.email,
            Password: x.password,
            'Is Active': x.enabled ? 'Active' : 'Inactive',
            FirstName: x.first_name,
            LastName: x.last_name,
            EENumber: x.ee_number,
            Created: created.toFormat('MM/dd/yyyy h:mm a'),
          };
        });

        // EE Number
        // Created

        setReport({
          columns: [
            { name: 'Username', selector: 'Username', sortable: true },
            { name: 'Password', selector: 'Password', sortable: true },
            { name: 'Is Active', selector: 'Is Active', sortable: true },
            { name: 'First Name', selector: 'FirstName', sortable: true },
            { name: 'Last Name', selector: 'LastName', sortable: true },
            { name: 'EE Number', selector: 'EENumber', sortable: true },
            { name: 'Created', selector: 'Created', sortable: true },
          ],
          data: reportData,
        });
      }

      setIsLoading(false);
    }
  }, [users]);

  useEffect(() => {
    if (id != 2) {
      return fetchWithToken(`/reports/${id}`, {
        method: 'GET',
      }).then((data) => {
        setName(data.name);
        setDescription(data.description);

        let columnsToFormatAsCurrency = [
          'Var F/(U) $',
          'Actual',
          'Budget',
          'Estimated Amount',
        ];

        columnsToFormatAsCurrency.forEach((column) => {
          let foundIndex = data.columns.findIndex((c) => c.name === column);

          if (foundIndex > -1) {
            data.columns[foundIndex].format = (row, index) => {
              let originalValue = row[column];

              let newValue = originalValue.toLocaleString('en', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });

              // Add parens if negative
              if (originalValue < 0) {
                newValue = newValue.replace('-', '');
                newValue = `(${newValue})`;
              }

              return newValue;
            };
          }
        });

        setReport(data);
        setIsLoading(false);
      });
    }
  }, []);

  function convertArrayOfObjectsToCSV(array) {
    const csv = parse(array);

    return csv;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array, filename = 'export') {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    let now = DateTime.now().toFormat('yyyy-MM-dd HH-mm-ss');
    const finalFilename = `${filename} - ${now}.csv`;

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', finalFilename);
    link.click();
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div style={{ padding: '.6em' }}>
            <h1
              style={{
                color: 'black',
                fontSize: '1.4em',
                marginBottom: '.5em',
              }}
              className="subtitle"
            >
              {name}
            </h1>
            <p>{description}</p>
          </div>
        </div>
        <div className="col-md-6" style={{ textAlign: 'right' }}>
          <button
            className="btn btn-outline-success btn-pill float-right"
            style={{ marginLeft: 'auto' }}
            onClick={() => downloadCSV(report.data, name)}
          >
            <FileDownloadIcon /> Export
          </button>
        </div>
      </div>

      <DataTable
        data={report.data}
        columns={report.columns}
        progressPending={isLoading}
        pagination
      />
    </>
  );
}
