import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Message from '../../components/Message';
import { fetchWithToken } from '../../fetcher';

export default function PostForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [selectedFile, setSelectedFile] = useState();

  let initialValues = {
    title: '',
    body: '',
  };

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      initialValues.title = props.initialValues.title;
      initialValues.body = props.initialValues.body;
    }
  }

  let validationSchema = yup.object({
    title: yup.string().required('Title is required'),
    body: yup.string().required('Content is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let imageId = null;

      if (selectedFile !== undefined) {
        const formData = new FormData();

        formData.append('file', selectedFile);

        let response = await fetchWithToken('/upload', {
          method: 'POST',
          body: formData,
        });

        imageId = response.id;
      }

      setSubmitting(true);

      if (props.isAdding) {
        props.handleSubmit(values, imageId);
      } else {
        props.handleSubmit(values);
      }
    },
  });

  const { getFieldProps } = formik;

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the Post is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the Post is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  const handleDelete = (id) => {
    setSubmitting(true);
    props.handleDelete(props.initialValues.id);
  };

  const onFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        {/* Title */}
        <div className="form-group col-md-12 mb-3">
          <label className="form-label">Name</label>

          <input
            {...getFieldProps('title')}
            className={`form-control ${
              formik.errors.title && formik.touched.title ? 'is-invalid' : ''
            }`}
            type="text"
            placeholder="Enter Post Title"
            autoFocus
          />

          {formik.errors.title && formik.touched.title ? (
            <span style={{ color: '#D53939' }}>{formik.errors.title}</span>
          ) : null}
        </div>

        {/* Content */}
        <div className="form-group col-md-12 mb-3">
          <label className="form-label">Content</label>

          <textarea
            {...getFieldProps('body')}
            className={`form-control ${
              formik.errors.body && formik.touched.body ? 'is-invalid' : ''
            }`}
            rows="6"
            placeholder="Add content here"
          />

          {formik.errors.body && formik.touched.body ? (
            <span style={{ color: '#D53939' }}>{formik.errors.body}</span>
          ) : null}
        </div>
      </div>

      {/* Image Chooser */}

      {props.isAdding && (
        <div className="form-group col-md-12 mb-3">
          <input type="file" onChange={onFileChange} />
        </div>
      )}

      {/* Submit Button */}
      <button
        className="btn btn-primary"
        style={{ marginTop: '1em' }}
        type="submit"
      >
        Submit
      </button>

      {/* Delete Button */}
      {!props.isAdding && (
        <button
          className="btn btn-danger"
          style={{ marginTop: '1em', marginLeft: '1em' }}
          onClick={() => handleDelete(props.id)}
          type="button"
        >
          Delete
        </button>
      )}
    </form>
  );
}
