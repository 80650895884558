const config = {
  development: {
    apiRootUrl: 'http://localhost:3000',
    apiSvcRootUrl: 'https://apisvc.ftiinmotion.com',
    api2Url: 'https://api2.ftiinmotion.com',
    appName: 'FTI In Motion',
    showGlobalAlert: false,
    globalAlertTitle: 'Demonstration Mode',
    globalAlertDescription:
      'This application is in demonstration mode and is not ready to be used for live data.',
    saveDialogTimeout: 1000,
    sendToSentry: false,
  },
  production: {
    apiRootUrl: 'https://api.ftiinmotion.com',
    apiSvcRootUrl: 'https://apisvc.ftiinmotion.com',
    api2Url: 'https://api2.ftiinmotion.com',
    appName: 'FTI In Motion',
    showGlobalAlert: false,
    globalAlertTitle: 'Demonstration Mode',
    globalAlertDescription:
      'This application is in demonstration mode and is not ready to be used for live data.',
    saveDialogTimeout: 1000,
    sendToSentry: true,
  },
};

export default config.production;
