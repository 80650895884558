import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';
import config from '../../config';

import DeliveryForm from './DeliveryForm';

const GET_DRIVERS = gql`
  query GetDrivers {
    getDrivers {
      id
      enabled
      first_name
      last_name
    }
  }
`;

const GET_TRUCKS = gql`
  query GetTrucks {
    getTrucks {
      id
      enabled
      truck_number
    }
  }
`;

const GET_PAYROLL_CYCLES = gql`
  query getPayrollCycles {
    getPayrollCycles {
      id
      week_start
      week_end
      lock
      enabled
      created
    }
  }
`;

const GET_DELIVERY = gql`
  query GetDelivery($id: Int!) {
    getDelivery(id: $id) {
      id
      driver {
        id
        first_name
        last_name
      }
      truck {
        id
        truck_number
      }
      delivery_date
      trip_type {
        id
        key
        name
      }
      notes {
        id
        note
      }
      avr
      linehaul_from
      linehaul_to
      bobtail
    }
  }
`;

const CREATE_DELIVERY = gql`
  mutation CreateDelivery(
    $delivery_date: String!
    $driver_id: Int!
    $truck_id: Int!
    $trip_type_id: Int!
    $avr: Int
    $linehaul_from: Int
    $linehaul_to: Int
    $bobtail: Int
  ) {
    createDelivery(
      delivery: {
        delivery_date: $delivery_date
        driver_id: $driver_id
        truck_id: $truck_id
        trip_type_id: $trip_type_id
        avr: $avr
        linehaul_from: $linehaul_from
        linehaul_to: $linehaul_to
        bobtail: $bobtail
      }
    ) {
      id
      enabled
      truck {
        id
      }
      delivery_date
    }
  }
`;

const CREATE_DELIVERY_NOTE = gql`
  mutation CreateDeliveryNote(
    $delivery_id: Int!
    $user_id: Int!
    $note: String!
  ) {
    createDeliveryNote(
      note: { delivery_id: $delivery_id, user_id: $user_id, note: $note }
    ) {
      id
      note
    }
  }
`;

const UPDATE_DELIVERY_NOTE = gql`
  mutation UpdateDeliveryNote($id: Int!, $note: String!) {
    updateDeliveryNote(id: $id, args: { note: $note }) {
      id
      note
    }
  }
`;

const CREATE_DELIVERY_IMAGE = gql`
  mutation CreateDeliveryImage(
    $delivery_id: Int!
    $user_id: Int!
    $image_id: Int!
  ) {
    createDeliveryImage(
      image: {
        delivery_id: $delivery_id
        user_id: $user_id
        image_id: $image_id
      }
    ) {
      id
    }
  }
`;

const DELETE_DELIVERY = gql`
  mutation DeleteDelivery($id: Int!) {
    deleteDelivery(id: $id)
  }
`;

const DELETE_DELIVERY_NOTE = gql`
  mutation DeleteDeliveryNote($id: Int!) {
    deleteDeliveryNote(id: $id)
  }
`;

const UPDATE_DELIVERY = gql`
  mutation UpdateDelivery(
    $id: Int!
    $delivery_date: String!
    $driver_id: Int!
    $truck_id: Int!
    $trip_type_id: Int!
    $avr: Int
    $linehaul_from: Int
    $linehaul_to: Int
    $bobtail: Int
  ) {
    updateDelivery(
      id: $id
      args: {
        delivery_date: $delivery_date
        driver_id: $driver_id
        truck_id: $truck_id
        trip_type_id: $trip_type_id
        avr: $avr
        linehaul_from: $linehaul_from
        linehaul_to: $linehaul_to
        bobtail: $bobtail
      }
    ) {
      id
      avr
    }
  }
`;

export default function AddEditDelivery(props) {
  const [getDelivery, delivery] = useLazyQuery(GET_DELIVERY, {
    fetchPolicy: 'no-cache',
  });

  const { loading: driversLoading, data: driversData } = useQuery(GET_DRIVERS, {
    fetchPolicy: 'no-cache',
  });
  const { loading: trucksLoading, data: trucksData } = useQuery(GET_TRUCKS, {
    fetchPolicy: 'no-cache',
  });
  const { loading: payrollCyclesLoading, data: payrollCyclesData } =
    useQuery(GET_PAYROLL_CYCLES);

  const { isTokenExpired, deleteToken, getUserId, hasPermission } = useToken();

  let { id } = useParams();

  // Load Delivery details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      getDelivery({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addDelivery, { error: addDeliveryError }] =
    useMutation(CREATE_DELIVERY);
  const [updateDelivery] = useMutation(UPDATE_DELIVERY);
  const [updateDeliveryNote] = useMutation(UPDATE_DELIVERY_NOTE);

  const [addDeliveryNote, { error: addDeliveryNoteError }] =
    useMutation(CREATE_DELIVERY_NOTE);

  const [addDeliveryImage] = useMutation(CREATE_DELIVERY_IMAGE);

  const [deleteDelivery] = useMutation(DELETE_DELIVERY);
  const [deleteDeliveryNote] = useMutation(DELETE_DELIVERY_NOTE);

  let history = useHistory();

  if (driversLoading || trucksLoading || payrollCyclesLoading)
    return 'Loading...';

  async function handleUpdateDelivery(delivery, values) {
    let {
      avr,
      deliveryDate,
      driver,
      linehaulFrom,
      linehaulTo,
      note,
      tripType,
      truck,
      bobtail,
    } = values;

    if (tripType == 1) {
      linehaulFrom = null;
      linehaulTo = null;
    } else if (tripType == 2) {
      avr = null;
    }

    if (note !== '') {
      // If none exists, add one
      if (delivery.notes.length === 0) {
        await addDeliveryNote({
          variables: {
            delivery_id: parseInt(delivery.id),
            user_id: driver,
            note,
          },
        });
      } else {
        let noteId = delivery.notes[0].id;

        await updateDeliveryNote({
          variables: {
            id: noteId,
            note,
          },
        });
      }
    } else {
      // Delete note if blank
      if (delivery.notes.length === 1) {
        let noteId = delivery.notes[0].id;

        await deleteDeliveryNote({
          variables: {
            id: noteId,
          },
        });
      }
    }

    updateDelivery({
      variables: {
        id: parseInt(id),
        delivery_date: deliveryDate,
        driver_id: parseInt(driver),
        truck_id: parseInt(truck),
        trip_type_id: parseInt(tripType),
        avr: parseInt(avr) || null,
        linehaul_from: parseInt(linehaulFrom) || null,
        linehaul_to: parseInt(linehaulTo) || null,
        bobtail: parseInt(bobtail) || 0
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/deliveries');
      }, config.saveDialogTimeout);
    });
  }

  async function handleDeleteDelivery(id) {
    deleteDelivery({
      variables: {
        id: parseInt(id),
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/deliveries');
      }, config.saveDialogTimeout);
    });
  }

  async function handleAddDelivery(values, imageId) {
    let {
      avr,
      deliveryDate,
      driver,
      linehaulFrom,
      linehaulTo,
      note,
      tripType,
      truck,
      bobtail,
    } = values;

    let variables = {};

    switch (tripType) {
      case '1':
        // This is a City Spot
        variables = {
          delivery_date: deliveryDate,
          driver_id: parseInt(driver),
          truck_id: parseInt(truck),
          trip_type_id: parseInt(tripType),
          avr: parseInt(avr),
          note,
        };
        break;
      case '2':
        // This is a Line Haul
        variables = {
          delivery_date: deliveryDate,
          driver_id: parseInt(driver),
          truck_id: parseInt(truck),
          trip_type_id: parseInt(tripType),
          linehaul_from: parseInt(linehaulFrom),
          linehaul_to: parseInt(linehaulTo),
          note,
          bobtail: parseInt(bobtail),
        };
        break;
      default:
        break;
    }

    try {
      let result = await addDelivery({
        variables,
      });

      if (note) {
        await addDeliveryNote({
          variables: {
            delivery_id: result.data.createDelivery.id,
            user_id: getUserId(),
            note,
          },
        });
      }

      if (imageId) {
        await addDeliveryImage({
          variables: {
            delivery_id: result.data.createDelivery.id,
            user_id: getUserId(),
            image_id: imageId,
          },
        });
      }

      setTimeout(() => {
        // history.push('/deliveries/add');
        window.location.reload();
      }, 1000);
    } catch (err) {
      let errorDescription = 'There was a problem adding this Delivery';

      if (err.message.indexOf('Truck disabled') > -1) {
        errorDescription = 'The selected Truck is currently disabled.';
      }

      return <Error description={errorDescription} />;
    }
  }

  if (addDeliveryError || addDeliveryNoteError) {
    let errorDescription = 'There was a problem adding this Delivery';

    if (addDeliveryError.message.indexOf('Truck disabled') > -1) {
      errorDescription = 'The selected Truck is currently disabled.';
    }

    return <Error description={errorDescription} />;
  }

  // Can this user see city spots and line hauls?
  let showTripTypeCitySpot = hasPermission('cityspot');
  let showTripTypeLineHaul = hasPermission('linehaul');

  // If this is an admin, allow all
  if (hasPermission('isadmin')) {
    showTripTypeCitySpot = true;
    showTripTypeLineHaul = true;
  }

  let initialValues = {
    delivery_date: DateTime.now().toFormat('yyyy-MM-dd'),
    driver: { id: getUserId() },
  };

  let isReadyToAdd =
    props.isAdding &&
    !driversLoading &&
    !trucksLoading &&
    !payrollCyclesLoading;

  let isReadyToEdit =
    !props.isAdding &&
    !driversLoading &&
    !trucksLoading &&
    !payrollCyclesLoading &&
    !delivery.loading &&
    delivery.called;

  return (
    <>
      {isReadyToAdd && (
        <DeliveryForm
          isAdding={props.isAdding}
          handleSubmit={handleAddDelivery}
          trucks={trucksData.getTrucks}
          drivers={driversData.getDrivers}
          payrollCycles={payrollCyclesData.getPayrollCycles}
          showTripTypeCitySpot={showTripTypeCitySpot}
          showTripTypeLineHaul={showTripTypeLineHaul}
          initialValues={initialValues}
        />
      )}

      {isReadyToEdit && (
        <DeliveryForm
          deliveryId={id}
          isAdding={props.isAdding}
          handleSubmit={handleUpdateDelivery}
          handleDelete={handleDeleteDelivery}
          trucks={trucksData.getTrucks}
          drivers={driversData.getDrivers}
          payrollCycles={payrollCyclesData.getPayrollCycles}
          showTripTypeCitySpot={showTripTypeCitySpot}
          showTripTypeLineHaul={showTripTypeLineHaul}
          initialValues={delivery.data.getDelivery}
        />
      )}
    </>
  );
}
