import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import useFilter from '../../hooks/useFilter';
import { EditIcon, PlusIcon } from '../../components/Icons';

import Error from '../../components/Error';
import SearchField from '../../components/SearchField';

const GET_STATIONS = gql`
  query GetStations {
    getStations {
      id
      enabled
      name
      notification_emails
      created
    }
  }
`;

export default function Stations() {
  const stations = useQuery(GET_STATIONS, {
    fetchPolicy: 'no-cache',
  });
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { isTokenExpired, deleteToken, hasPermission } = useToken();
  const { filter } = useFilter();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (stations.data) {
      let filteredData = filter(stations.data.getStations, searchValue, [
        'name',
      ]);

      setFilteredData(filteredData);
    }
  }, [searchValue, stations.data]);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  if (stations.loading) return <h3>Loading...</h3>;

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Notification Emails',
      selector: (row) => row.notification_emails,
      format: (row) => {
        if (row.notification_emails !== null) {
          if (row.notification_emails.length > 0) {
            let emails = row.notification_emails.split(',');
            return emails.length;
          }
        }

        return 0;
      },
    },
    {
      name: 'Is Active',
      selector: (row) => row.enabled,
      sortable: true,
      cell: (row) => (
        <div>
          <span
            className={`badge ${row.enabled ? 'bg-success' : 'bg-danger'} me-1`}
          />
          {row.enabled ? 'Yes' : 'No'}
        </div>
      ),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <Link to={`/stations/${row.id}`} className="btn btn-link">
          <EditIcon /> Edit
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <SearchField value={searchValue} setValue={setSearchValue} />
        </div>

        <div className="col-md-6" style={{ textAlign: 'right' }}>
          <Link
            to="/stations/add"
            className="btn btn-outline-success btn-pill float-right"
            style={{ marginLeft: 'auto' }}
          >
            <PlusIcon /> Add Station
          </Link>
        </div>
      </div>

      <div className="card mt-2 mb-1">
        <DataTable data={filteredData} columns={columns} pagination striped />
      </div>
    </>
  );
}
