import { AlertTriangleIcon } from './Icons';

export default function Alert(props) {
  return (
    <div className="card card-sm">
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-auto">
            <span className="bg-red text-white avatar">
              <AlertTriangleIcon />
            </span>
          </div>
          <div className="col">
            <div className="font-weight-medium">{props.title}</div>
            <div className="text-muted">{props.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
