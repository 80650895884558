import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './ErrorBoundary';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://2797a5e8211244db81ea1bdd5a48bbbd@o183418.ingest.sentry.io/5654655',
  beforeSend(event) {
    try {
      if (
        event.message.indexOf(
          `Cannot read properties of null (reading 'userId')` > -1,
        )
      ) {
        return null;
      }
    } catch (err) {
      
    }

    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
