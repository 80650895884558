function flatten(data, c) {
  var result = {};
  for (var i in data) {
    if (typeof data[i] == 'object')
      Object.assign(result, flatten(data[i], c + '.' + i));
    else result[(c + '.' + i).replace(/^\./, '')] = data[i];
  }
  return result;
}

export default function useFilter() {
  const filter = (data, searchTerm, properties = []) => {
    // Loop through all values of an object and return if we find matches
    if (data !== undefined) {
      let filteredData = data.filter((x) => {
        let match = false;

        let flatData = flatten(x, '');

        // Filter based on properties listed above
        for (const [key, value] of Object.entries(flatData)) {
          if (properties.indexOf(key) > -1 || properties.length === 0) {
            if (value) {
              if (
                value
                  .toString()
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) > -1
              ) {
                match = true;
              }
            }
          }
        }

        if (match) {
          return x;
        }
      });

      return filteredData;
    }
  };

  return {
    filter,
  };
}
