import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import { EditIcon } from '../../components/Icons';
import config from '../../config';

const GET_PURCHASE_ORDERS = gql`
  query GetPurchaseOrders {
    getPurchaseOrders {
      id
      enabled
      description
      user {
        id
        first_name
        last_name
        ee_number
      }
      truck {
        id
        truck_number
        station {
          name
        }
        entity {
          name
        }
      }
      station {
        id
        name
      }
      vendor {
        id
        name
      }
      images {
        id
        created
      }
      estimated_amount
      status {
        id
        name
      }
      created
    }
  }
`;

const columns = [
  {
    name: 'PO #',
    selector: (row) => row.id,
    grow: 0,
  },
  {
    name: 'Description',
    selector: (row) => row.description,
    grow: 2,
  },
  // {
  //   name: 'Requester',
  //   selector: (row) => `${row.user.first_name} ${row.user.last_name}`,
  // },
  {
    name: 'Truck',
    selector: (row) => row.truck.truck_number,
  },
  {
    name: 'Station',
    selector: (row) => row.station.name,
  },
  // {
  //   name: 'Vendor',
  //   selector: (row) => row.vendor.name,
  // },
  {
    name: 'Amount',
    selector: (row) => row.estimated_amount,
  },
  {
    name: 'Status',
    selector: (row) => row.status.name,
  },
  {
    name: 'Created',
    selector: (row) => row.created,
    format: (row, index) => {
      // Parse UTC value and convert to EDT
      let date = DateTime.fromMillis(parseInt(row.created)).toUTC();

      return date.toFormat('MM/dd/yyyy');
    },
  },
  {
    name: 'Image',
    cell: (row) => {
      let image = null;

      if (row.images.length > 0) {
        image = row.images[0];
      }

      if (image) {
        let imageUrl = `${config.apiRootUrl}/file/${image.id}`;
        return (
          <a href={imageUrl} rel="noreferrer" target="_blank">
            View
          </a>
        );
      } else {
        return <div>-</div>;
      }
    },
    grow: 2,
    hide: 'sm',
  },
  {
    name: 'Actions',
    cell: (row) => {
      return (
        <>
          <Link to={`/purchaseorders/${row.id}`} className="btn btn-link">
            <EditIcon /> Edit
          </Link>
        </>
      );
    },
  },
];

export default function PurchaseOrders() {
  const { loading, data } = useQuery(GET_PURCHASE_ORDERS, {
    fetchPolicy: 'no-cache',
  });

  const [filteredData, setFilteredData] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // Filters
  const [trucksToFilter, setTrucksToFilter] = useState([]);
  const [truckFilter, setTruckFilter] = useState();

  const [stationsToFilter, setStationsToFilter] = useState([]);
  const [stationsFilter, setStationsFilter] = useState();

  const [statusesToFilter, setStatusesToFilter] = useState([]);
  const [statusesFilter, setStatusesFilter] = useState();

  const [vendorsToFilter, setVendorsToFilter] = useState([]);
  const [vendorsFilter, setVendorsFilter] = useState();

  const [usersToFilter, setUsersToFilter] = useState([]);
  const [usersFilter, setUsersFilter] = useState();

  const [createdAfterFilter, setCreatedAfterFilter] = useState();
  const [createdBeforeFilter, setCreatedBeforeFilter] = useState();

  const { isTokenExpired, deleteToken, getUserId, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (data) {
      if (!hasPermission('approvepo')) {
        // Limit to just this user's PO's

        data.getPurchaseOrders = data.getPurchaseOrders.filter(
          (x) => x.user.id === getUserId()
        );
      }

      // Get Trucks to Filter by
      let uniqueTrucks = [
        ...new Set(data.getPurchaseOrders.map((x) => x.truck.id)),
      ].map((x) => {
        // Get full record
        let record = data.getPurchaseOrders.filter((y) => y.truck.id === x)[0];

        return {
          id: x,
          truck_number: record.truck.truck_number,
        };
      });

      setTrucksToFilter(uniqueTrucks);

      // Station
      // Get Stations to Filter by
      let uniqueStations = [
        ...new Set(data.getPurchaseOrders.map((x) => x.station.id)),
      ].map((x) => {
        // Get full record
        let record = data.getPurchaseOrders.filter(
          (y) => y.station.id === x
        )[0];

        return {
          id: x,
          name: record.station.name,
        };
      });

      setStationsToFilter(uniqueStations);

      // Status
      // Get Statuses to Filter by
      let uniqueStatuses = [
        ...new Set(data.getPurchaseOrders.map((x) => x.status.id)),
      ].map((x) => {
        // Get full record
        let record = data.getPurchaseOrders.filter((y) => y.status.id === x)[0];

        return {
          id: x,
          name: record.status.name,
        };
      });

      setStatusesToFilter(uniqueStatuses);

      // Vendors
      // Get Vendors to Filter by
      let uniqueVendors = [
        ...new Set(data.getPurchaseOrders.map((x) => x.vendor.id)),
      ].map((x) => {
        // Get full record
        let record = data.getPurchaseOrders.filter((y) => y.vendor.id === x)[0];

        return {
          id: x,
          name: record.vendor.name,
        };
      });

      setVendorsToFilter(uniqueVendors);

      // Users
      // Get User to Filter by
      let uniqueUsers = [
        ...new Set(data.getPurchaseOrders.map((x) => x.user.id)),
      ].map((x) => {
        // Get full record
        let record = data.getPurchaseOrders.filter((y) => y.user.id === x)[0];

        return {
          id: x,
          name: `${record.user.first_name} ${record.user.last_name}`,
        };
      });

      setUsersToFilter(uniqueUsers);

      let filteredRecords = data.getPurchaseOrders.filter((x) => {
        let showRecord = true;

        // Filter by Truck
        if (truckFilter) {
          if (x.truck.id != truckFilter) {
            showRecord = false;
          }
        }

        // Stations
        if (stationsFilter) {
          if (x.station.id != stationsFilter) {
            showRecord = false;
          }
        }

        // Statuses
        if (statusesFilter) {
          if (x.status.id != statusesFilter) {
            showRecord = false;
          }
        }

        // Vendors
        if (vendorsFilter) {
          if (x.vendor.id != vendorsFilter) {
            showRecord = false;
          }
        }

        // Vendors
        if (usersFilter) {
          if (x.user.id != usersFilter) {
            showRecord = false;
          }
        }

        // Created After
        if (createdAfterFilter) {
          let createdAfterDate = DateTime.fromISO(createdAfterFilter).toUTC().startOf('day');
          let createdDate = DateTime.fromMillis(parseInt(x.created)).toUTC().startOf('day');
          
          if(createdDate < createdAfterDate) {
            showRecord = false;
          }
        }

        // Created Before
        if (createdBeforeFilter) {
          let createdBeforeDate = DateTime.fromISO(createdBeforeFilter).toUTC().startOf('day');
          let createdDate = DateTime.fromMillis(parseInt(x.created)).toUTC().startOf('day');
          
          if(createdDate >= createdBeforeDate) {
            showRecord = false;
          }
        }

        return showRecord;
      });

      setFilteredData(filteredRecords);
    }
  }, [
    data,
    truckFilter,
    stationsFilter,
    statusesFilter,
    vendorsFilter,
    usersFilter,
    createdAfterFilter,
    createdBeforeFilter,
  ]);

  if (loading) return <p>Loading...</p>;

  if (isFirstLoad) {
    setIsFirstLoad(false);
  }

  const handleFilterChange = (e) => {
    switch (e.target.name) {
      case 'truck':
        let parsedTruck = parseInt(e.target.value);

        if (!isNaN(parsedTruck)) {
          setTruckFilter(parsedTruck);
        } else {
          setTruckFilter(undefined);
        }

        break;
      case 'station':
        let parsedStation = parseInt(e.target.value);

        if (!isNaN(parsedStation)) {
          setStationsFilter(parsedStation);
        } else {
          setStationsFilter(undefined);
        }

        break;
      case 'status':
        let parsedStatus = parseInt(e.target.value);

        if (!isNaN(parsedStatus)) {
          setStatusesFilter(parsedStatus);
        } else {
          setStatusesFilter(undefined);
        }

        break;
      case 'vendor':
        let parsedVendor = parseInt(e.target.value);

        if (!isNaN(parsedVendor)) {
          setVendorsFilter(parsedVendor);
        } else {
          setVendorsFilter(undefined);
        }
        break;
      case 'user':
        let parsedUser = parseInt(e.target.value);

        if (!isNaN(parsedUser)) {
          setUsersFilter(parsedUser);
        } else {
          setUsersFilter(undefined);
        }
        break;
      case 'createdAfter':
        setCreatedAfterFilter(e.target.value);
        break;
      case 'createdBefore':
        setCreatedBeforeFilter(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {!loading && (
        <>
          <div className="row">
            {/* Truck, Station, Status, Vendor, User */}

            {/* Truck */}
            <div className="form-group col-md-3 mb-3">
              <label className="form-label">Truck</label>

              <select
                name="truck"
                className="form-select"
                type="text"
                onChange={handleFilterChange}
              >
                <option value="">Select</option>

                {trucksToFilter.map((x) => {
                  return (
                    <option key={x.id} value={x.id}>
                      {x.truck_number}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Station */}
            <div className="form-group col-md-3 mb-3">
              <label className="form-label">Station</label>

              <select
                name="station"
                className="form-select"
                type="text"
                onChange={handleFilterChange}
              >
                <option value="">Select</option>

                {stationsToFilter.map((x) => {
                  return (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Status */}
            <div className="form-group col-md-3 mb-3">
              <label className="form-label">Status</label>

              <select
                name="status"
                className="form-select"
                type="text"
                onChange={handleFilterChange}
              >
                <option value="">Select</option>

                {statusesToFilter.map((x) => {
                  return (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Vendor */}
            <div className="form-group col-md-3 mb-3">
              <label className="form-label">Vendor</label>

              <select
                name="vendor"
                className="form-select"
                type="text"
                onChange={handleFilterChange}
              >
                <option value="">Select</option>

                {vendorsToFilter.map((x) => {
                  return (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* User */}
            <div className="form-group col-md-3 mb-3">
              <label className="form-label">User</label>

              <select
                name="user"
                className="form-select"
                type="text"
                onChange={handleFilterChange}
              >
                <option value="">Select</option>

                {usersToFilter.map((x) => {
                  return (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Created On or After */}
            <div className="form-group col-md-3 mb-3">
              <label className="form-label">Created On or After</label>

              <input
                name="createdAfter"
                className="form-control"
                type="date"
                onChange={handleFilterChange}
              />
            </div>

            {/* Created Before */}
            <div className="form-group col-md-3 mb-3">
              <label className="form-label">Created Before</label>

              <input
                name="createdBefore"
                className="form-control"
                type="date"
                onChange={handleFilterChange}
              />
            </div>
          </div>

          <div className="card mt-2 mb-1">
            <DataTable
              className="table card-table"
              data={filteredData}
              columns={columns}
              pagination
              striped
            />
          </div>
        </>
      )}
    </>
  );
}
