import config from './config';

import axios from 'axios';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { ErrorLink } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';

const errorLink = new ErrorLink(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      if (networkError.statusCode === 401) {
        localStorage.removeItem('token');
        window.location.replace('/');
      }
    }
  }
);

const apiLink = new HttpLink({
  uri: `${config.apiRootUrl}/graphql`,
});

const apiSvcLink = new HttpLink({
  uri: `${config.apiSvcRootUrl}/graphql`,
});

const finalApiLink = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'apisvc',
  apiSvcLink,
  apiLink
);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  };
});

// Set up Apollo Client
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, finalApiLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;
