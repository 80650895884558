import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import Message from '../../components/Message';
import { useEffect } from 'react';

export default function BudgetForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [validMonths, setValidMonths] = useState([]);

  let initialValues = {
    station_id: '',
    amount: '',
    start_date: '',
    end_date: '',
  };

  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      if (props.isAdding) {
        initialValues.station_id = props.initialValues.station_id;
      } else {
        initialValues.station_id = props.initialValues.station.id;
      }
      initialValues.amount = props.initialValues.amount;
      initialValues.start_date = props.initialValues.start_date;
      initialValues.end_date = props.initialValues.end_date;
    }
  }

  useEffect(() => {
    if (props.budgets !== undefined) {
      if (props.budgets.data !== undefined) {
        // Get list of months, with start and end dates, formatted as start month and year
        // Calculate which Months to show in the dropdown
        let thisMonth = DateTime.now().startOf('month');
        let threeMonthsAgo = thisMonth.minus({ days: 90 }).startOf('month');
        let sixMonthsAhead = thisMonth.plus({ days: 180 }).startOf('month');

        let months = [];

        let cursor = threeMonthsAgo;

        while (cursor <= sixMonthsAhead) {
          let existingBudgets = props.budgets.data.getBudgetsByStationId;

          let includeInMonthList = true;

          if (existingBudgets !== undefined) {
            for (const b of existingBudgets) {
              let bDT = DateTime.fromMillis(parseInt(b.start_date)).toUTC();

              if (cursor.toLocaleString() === bDT.toLocaleString()) {
                includeInMonthList = false;
              }
            }

            if (includeInMonthList) {
              months.push({
                startDate: cursor,
              });
            }
          }

          cursor = cursor.plus({ month: 1 });
        }

        setValidMonths(months);
      }
    }
  }, [props.budgets]);

  let validationSchemaPlain = {};

  validationSchemaPlain.amount = yup
    .number()
    .typeError('Amount must be a number')
    .required('Amount is required');

  if (props.isAdding) {
    validationSchemaPlain.month = yup
      .string('Month must be selected')
      .required('Month must be selected');
  }

  let validationSchema = yup.object(validationSchemaPlain);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      month: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      props.handleSubmit(values);
    },
  });

  const { getFieldProps } = formik;

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the Budget is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the Budget is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  let monthList = validMonths.map((x, idx) => {
    return (
      <option key={idx} value={x.startDate}>
        {x.startDate.toFormat('MMMM yyyy')}
      </option>
    );
  });

  let startDateFormatted = DateTime.fromMillis(
    parseInt(initialValues.start_date)
  )
    .toUTC()
    .toFormat('MMMM yyyy');

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        {/* Month */}
        {props.isAdding && (
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Month</label>

            <select
              {...getFieldProps('month')}
              className={`form-select ${
                formik.errors.month && formik.touched.month ? 'is-invalid' : ''
              }`}
              type="text"
            >
              <option value="">Choose a Month</option>

              {monthList}
            </select>

            {formik.errors.month && formik.touched.month ? (
              <span style={{ color: '#D53939' }}>{formik.errors.month}</span>
            ) : null}
          </div>
        )}

        {!props.isAdding && (
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Month</label>

            {startDateFormatted}

            {formik.errors.month && formik.touched.month ? (
              <span style={{ color: '#D53939' }}>{formik.errors.month}</span>
            ) : null}
          </div>
        )}

        {/* Amount */}
        <div className="form-group col-md-6 mb-3">
          <label className="form-label">Amount</label>
          <input
            {...getFieldProps('amount')}
            className={`form-control ${
              formik.errors.amount && formik.touched.amount ? 'is-invalid' : ''
            }`}
            type="text"
            placeholder="Enter Amount"
            autoFocus
          />

          {formik.errors.amount && formik.touched.amount ? (
            <span style={{ color: '#D53939' }}>{formik.errors.amount}</span>
          ) : null}
        </div>
      </div>

      {/* Submit Button */}
      <button
        className="btn btn-primary"
        style={{ marginTop: '1em' }}
        type="submit"
      >
        Submit
      </button>
    </form>
  );
}
