import fp from 'fresh-password';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EyeIcon, EyeOffIcon } from '../../components/Icons';
import Message from '../../components/Message';

export default function UserForm(props) {
  let [submitting, setSubmitting] = useState(false);
  let [useDefaultPassword, setUseDefaultPassword] = useState(true);

  let initialValues = {
    firstName: '',
    lastName: '',
    eeNumber: '',
    emailAddress: '',
    password: '',
    enabled: false,
    permissions: [],
  };

  // Set initial values from parent, if provided
  if (props.initialValues) {
    if (Object.keys(props.initialValues).length > 0) {
      let permissionsOnly =
        props.initialValues.permissions.map((x) => x.key) || [];

      initialValues.firstName = props.initialValues.first_name || '';
      initialValues.lastName = props.initialValues.last_name || '';
      initialValues.eeNumber = props.initialValues.ee_number || '';
      initialValues.emailAddress = props.initialValues.email || '';
      initialValues.permissions = permissionsOnly;
      initialValues.password = props.initialValues.password || '';
      initialValues.enabled = props.initialValues.enabled;
    }
  }

  let schema = {
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    eeNumber: yup.number().typeError('EE Number must be a number'),
    emailAddress: yup
      .string()
      .email('Email Address must be valid')
      .required('Email Address is required'),
  };

  if (props.isAdding) {
    schema.password = yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        'Must Contain at least eight characters, at least one letter and one number'
      );
  }

  let validationSchema = yup.object(schema);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      props.handleSubmit(values);
    },
  });

  const { getFieldProps } = formik;

  let [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (props.isAdding) {
      if (useDefaultPassword) {
        formik.values.password = '999UseDefaultPassword';
      } else {
        formik.values.password = '';
      }
    }
  }, [useDefaultPassword]);

  // Generate Password
  function handleGeneratePassword(e) {
    e.preventDefault();

    let password = fp.generate();

    formik.setFieldValue('password', password);

    setShowPassword(true);
  }

  // Show or hide the password
  function handleTogglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  if (submitting) {
    let title = 'Creating';
    let description = 'Please wait while the User is being created';

    if (!props.isAdding) {
      title = 'Updating';
      description = 'Please wait while the User is being updated';
    }

    return (
      <Message title={title} description={description} showProgress={true} />
    );
  }

  let showPasswordBox =
    (props.isAdding && !useDefaultPassword) || !props.isAdding;

  let permissionCategories = [
    ...new Set(props.availablePermissions.map((x) => x.category)),
  ];

  return (
    <>
      {props.isAdding && <h3>Adding new User</h3>}
      {!props.isAdding && <h3>Editing User {props.initialValues.id}</h3>}
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* First Name */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">First Name</label>

            <input
              {...getFieldProps('firstName')}
              className={`form-control ${
                formik.errors.firstName && formik.touched.firstName
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
              placeholder="Enter First Name"
              autoFocus
            />

            {formik.errors.firstName && formik.touched.firstName ? (
              <span style={{ color: '#D53939' }}>
                {formik.errors.firstName}
              </span>
            ) : null}
          </div>

          {/* Last Name */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Last Name</label>

            <input
              {...getFieldProps('lastName')}
              className={`form-control ${
                formik.errors.lastName && formik.touched.lastName
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
              placeholder="Enter Last Name"
            />

            {formik.errors.lastName && formik.touched.lastName ? (
              <span style={{ color: '#D53939' }}>{formik.errors.lastName}</span>
            ) : null}
          </div>

          {/* EE Number */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">EE Number</label>

            <input
              {...getFieldProps('eeNumber')}
              className={`form-control ${
                formik.errors.eeNumber && formik.touched.eeNumber
                  ? 'is-invalid'
                  : ''
              }`}
              type="text"
              placeholder="Enter EE Number"
            />

            {formik.errors.eeNumber && formik.touched.eeNumber ? (
              <span style={{ color: '#D53939' }}>{formik.errors.eeNumber}</span>
            ) : null}
          </div>

          {/* Email Address */}
          <div className="form-group col-md-6 mb-3">
            <label className="form-label">Enter Email Address</label>

            <div className="input-icon">
              <input
                {...getFieldProps('emailAddress')}
                type="text"
                className={`form-control ${
                  formik.errors.emailAddress && formik.touched.emailAddress
                    ? 'is-invalid'
                    : ''
                }`}
                placeholder="Email Address"
              />

              {formik.errors.emailAddress && formik.touched.emailAddress ? (
                <span style={{ color: '#D53939' }}>
                  {formik.errors.emailAddress}
                </span>
              ) : null}
            </div>
          </div>

          {/* Is Active? */}
          {!props.isAdding && (
            <div className="form-group col-md-6 mb-3">
              <label className="form-label">Settings</label>
              <label className="form-check mb-2">
                <input
                  {...getFieldProps('enabled')}
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={initialValues.enabled}
                />
                <span className="form-check-label">Is Active?</span>
              </label>
            </div>
          )}

          {props.isAdding && (
            <>
              {/* Use Default Password */}
              <div className="form-group col-md-12 mb-3">
                <label className="form-label">Use Default Password</label>
                <div className="row">
                  <div className="col-4">
                    <label className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="useDefaultPassword"
                        defaultChecked={useDefaultPassword}
                        onChange={(e) => {
                          setUseDefaultPassword(e.target.checked);
                        }}
                      />
                      <span className="form-check-label">
                        Use Default Password
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* Password */}
          {showPasswordBox && (
            <div className="form-group col-md-6 mb-3">
              <label className="form-label">Password</label>

              <div className="input-group input-group-flat">
                <input
                  {...getFieldProps('password')}
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  autoComplete="off"
                />
                <span className="input-group-text">
                  <a
                    href="#"
                    className="link-secondary"
                    style={{ marginLeft: '.5em' }}
                    title=""
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword && <EyeOffIcon />}
                    {!showPassword && <EyeIcon />}
                  </a>
                </span>
                <a
                  href="#"
                  className="input-group-link btn btn-sm btn-primary"
                  onClick={handleGeneratePassword}
                >
                  Generate
                </a>
              </div>

              {formik.errors.password && formik.touched.password ? (
                <span style={{ color: '#D53939' }}>
                  {formik.errors.password}
                </span>
              ) : null}
            </div>
          )}

          {/* Permissions */}
          {permissionCategories.map((cat, index) => {
            let permissionsForCategory = props.availablePermissions
              .filter((x) => x.category === cat && x.enabled === true)
              .sort((a, b) => {
                return a.name.localeCompare(b.name);
              });

            return (
              <div className="form-group col-md-12 mb-3">
                <label className="form-label">{cat}</label>
                <div className="row">
                  {permissionsForCategory.map((x, idx) => {
                    return (
                      <div key={idx} className="col-4">
                        <label className="form-check mb-2">
                          <input
                            {...getFieldProps('permissions')}
                            className="form-check-input"
                            type="checkbox"
                            name="permissions"
                            value={x.key}
                            defaultChecked={formik.initialValues.permissions.includes(
                              x.key
                            )}
                          />
                          <span className="form-check-label">{x.name}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {/* Submit Button */}
        <button
          className="btn btn-primary"
          style={{ marginTop: '1em' }}
          type="submit"
        >
          Submit
        </button>
      </form>
    </>
  );
}
