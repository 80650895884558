import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';

import UserForm from './UserForm';

const GET_PERMISSIONS = gql`
  query {
    getPermissions {
      id
      key
      enabled
      category
      name
      description
    }
  }
`;

const ADD_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $eeNumber: Int
    $email: String!
    $password: String!
    $permissions: [String]
  ) {
    createUser(
      user: {
        first_name: $firstName
        last_name: $lastName
        ee_number: $eeNumber
        email: $email
        password: $password
        permissions: $permissions
      }
    ) {
      id
      enabled
      first_name
      permissions {
        id
        key
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: Int!
    $enabled: Boolean!
    $eeNumber: Int
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String
    $permissions: [String]
  ) {
    updateUser(
      id: $id
      args: {
        ee_number: $eeNumber
        enabled: $enabled
        first_name: $firstName
        last_name: $lastName
        email: $email
        password: $password
        permissions: $permissions
      }
    ) {
      id
      enabled
      first_name
      permissions {
        id
        key
      }
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: Int!) {
    getUser(id: $id) {
      id
      enabled
      first_name
      last_name
      ee_number
      email
      password
      permissions {
        id
        key
        name
        description
      }
    }
  }
`;

export default function AddEditUser(props) {
  const [addError, setAddError] = useState();
  const [errorEmailAddress, setErrorEmailAddress] = useState();
  const { loading, data } = useQuery(GET_PERMISSIONS);
  const [getUser, user] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache',
  });
  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  const [addUser] =
    useMutation(ADD_USER);

  const [updateUser] = useMutation(UPDATE_USER);

  let history = useHistory();

  let { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      getUser({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (loading) return 'Loading...';

  function handleUpdateUser(values) {
    let { firstName, lastName, emailAddress, permissions, enabled, eeNumber, password } =
      values;

    updateUser({
      variables: {
        id: parseInt(id),
        firstName,
        lastName,
        email: emailAddress,
        permissions,
        enabled,
        password,
        eeNumber: parseInt(eeNumber),
      },
    }).then(() => {
      // toast(`Updated User ${firstName} ${lastName}`);

      setTimeout(() => {
        history.push('/users');
      }, 1000);
    });
  }

  function handleAddUser(values) {
    let { firstName, lastName, emailAddress, password, permissions, eeNumber } = values;

    addUser({
      variables: {
        firstName,
        lastName,
        email: emailAddress,
        eeNumber: parseInt(eeNumber),
        password,
        permissions,
      },
    })
      .then(() => {
        setTimeout(() => {
          history.push('/users');
        }, 1000);
      })
      .catch((err) => {
        setAddError(err);
        setErrorEmailAddress(emailAddress);
      });
  }

  if (addError) {
    let description = 'There was a problem adding this User';

    if (addError.message.indexOf('ER_DUP_ENTRY') > -1) {
      description = `User with email ${errorEmailAddress} already exists!`;
    }

    return <Error description={description} />;
  }

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  return (
    <div>
      {props.isAdding && (
        <UserForm
          isAdding={props.isAdding}
          handleSubmit={handleAddUser}
          availablePermissions={data.getPermissions}
        ></UserForm>
      )}

      {!props.isAdding && !user.loading && user.called && (
        <UserForm
          isAdding={props.isAdding}
          handleSubmit={handleUpdateUser}
          availablePermissions={data.getPermissions}
          initialValues={user.data.getUser}
        ></UserForm>
      )}

      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />

      {/* {mutationLoading && <p>Loading...</p>}
      {mutationError && <p>Error :( Please try again</p>} */}
    </div>
  );
}
