import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import useToken from '../../hooks/useToken';
import Error from '../../components/Error';
import config from '../../config';

import PurchaseOrderForm from './PurhaseOrderForm';

const GET_TRUCKS = gql`
  query GetTrucks {
    getTrucks {
      id
      enabled
      truck_number
      station {
        id
      }
    }
  }
`;

const GET_STATIONS = gql`
  query GetStations {
    getStations {
      id
      enabled
      name
      budget
      notification_emails
      created
    }
  }
`;

const GET_VENDORS = gql`
  query GetVendors {
    getVendors {
      id
      enabled
      name
      created
    }
  }
`;

const GET_STATUSES = gql`
  query GetStatuses {
    getStatuses {
      id
      enabled
      name
    }
  }
`;

const GET_PURCHASEORDER = gql`
  query GetPurchaseOrder($id: Int!) {
    getPurchaseOrder(id: $id) {
      id
      enabled
      description
      user {
        id
        first_name
        last_name
        ee_number
      }
      truck {
        id
        truck_number
        station {
          name
        }
        entity {
          name
        }
      }
      station {
        id
        name
      }
      vendor {
        id
        name
      }
      images {
        id
        created
      }
      estimated_amount
      status {
        id
        name
      }
      created
    }
  }
`;

const CREATE_VENDOR = gql`
  mutation CreateVendor($name: String!) {
    createVendor(vendor: { name: $name }) {
      id
      enabled
      name
      created
    }
  }
`;

const DELETE_PURCHASE_ORDER = gql`
  mutation DeletePurchaseOrder($id: Int!) {
    deletePurchaseOrder(id: $id)
  }
`;

const CREATE_PURCHASE_ORDER = gql`
  mutation CreatePurchaseOrder(
    $description: String!
    $truck_id: Int!
    $station_id: Int!
    $vendor_id: Int!
    $estimated_amount: Float!
    $user_id: Int!
  ) {
    createPurchaseOrder(
      purchaseOrder: {
        description: $description
        truck_id: $truck_id
        station_id: $station_id
        vendor_id: $vendor_id
        estimated_amount: $estimated_amount
        user_id: $user_id
      }
    ) {
      id
      enabled
      description
      user {
        id
        first_name
        last_name
        ee_number
      }
      truck {
        id
        truck_number
        station {
          name
        }
        entity {
          name
        }
      }
      station {
        name
      }
      vendor {
        name
      }
      estimated_amount
      status {
        id
        name
      }
      created
    }
  }
`;

const CREATE_PURCHASE_ORDER_IMAGE = gql`
  mutation CreatePurchaseOrdermage(
    $purchase_order_id: Int!
    $user_id: Int!
    $image_id: Int!
  ) {
    createPurchaseOrderImage(
      image: {
        purchase_order_id: $purchase_order_id
        user_id: $user_id
        image_id: $image_id
      }
    ) {
      id
    }
  }
`;

const UPDATE_PURCHASE_ORDER = gql`
  mutation UpdatePurchaseOrder(
    $id: Int!
    $description: String
    $truck_id: Int
    $station_id: Int
    $vendor_id: Int
    $status_id: Int
    $estimated_amount: Float
  ) {
    updatePurchaseOrder(
      id: $id
      args: {
        description: $description
        truck_id: $truck_id
        station_id: $station_id
        vendor_id: $vendor_id
        status_id: $status_id
        estimated_amount: $estimated_amount
      }
    ) {
      id
      enabled
      description
      user {
        id
        first_name
        last_name
        ee_number
      }
      truck {
        id
        truck_number
        station {
          name
        }
        entity {
          name
        }
      }
      station {
        name
      }
      vendor {
        name
      }
      estimated_amount
      status {
        id
        name
      }
      created
    }
  }
`;

export default function AddEditPurchaseOrder(props) {
  const [getPurchaseOrder, purchaseOrder] = useLazyQuery(GET_PURCHASEORDER, {
    fetchPolicy: 'no-cache',
  });

  const [addPurchaseOrderImage] = useMutation(CREATE_PURCHASE_ORDER_IMAGE);

  const [updatePurchaseOrder] = useMutation(UPDATE_PURCHASE_ORDER);
  const [deletePurchaseOrder] = useMutation(DELETE_PURCHASE_ORDER);

  const { loading: trucksLoading, data: trucksData } = useQuery(GET_TRUCKS);

  // Stations
  const { loading: stationsLoading, data: stationsData } =
    useQuery(GET_STATIONS);

  // Vendors
  const { loading: vendorsLoading, data: vendorsData } = useQuery(GET_VENDORS);

  // Statuses
  const { loading: statusesLoading, data: statusesData } =
    useQuery(GET_STATUSES);

  const [
    createVendor,
    { error: createVendorError },
  ] = useMutation(CREATE_VENDOR, {
    refetchQueries: ['GetVendors'],
  });

  const { isTokenExpired, deleteToken, getUserId } = useToken();

  let { id } = useParams();

  // Load Purchase Order details if we're editing
  useEffect(() => {
    if (id !== undefined) {
      getPurchaseOrder({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id]);

  if (isTokenExpired()) {
    deleteToken();
  }

  const [createPurchaseOrder, { error: createPurchaseOrderError }] =
    useMutation(CREATE_PURCHASE_ORDER);

  let history = useHistory();

  if (trucksLoading || stationsLoading || vendorsLoading || statusesLoading)
    return 'Loading...';

  async function handleUpdatePurchaseOrder(purchaseOrder, values) {
    let { description, estimatedAmount, station, truck, vendor } = values;

    await updatePurchaseOrder({
      variables: {
        id: parseInt(id),
        description,
        estimated_amount: parseFloat(estimatedAmount),
        station_id: parseInt(station),
        truck_id: parseInt(truck),
        vendor_id: parseInt(vendor),
      },
    });

    try {
      setTimeout(() => {
        history.push('/purchaseorders');
      }, config.saveDialogTimeout);
    } catch (err) {
      let description = 'There was a problem updating this Purchase Order';

      return <Error description={description} />;
    }
  }

  async function handleDeletePurchaseOrder(id) {
    deletePurchaseOrder({
      variables: {
        id: parseInt(id),
      },
    }).then(() => {
      setTimeout(() => {
        history.push('/purchaseorders');
      }, config.saveDialogTimeout);
    });
  }

  async function handleAddPurchaseOrder(values, imageId) {
    let { description, estimatedAmount, station, truck, vendor } = values;

    let result = await createPurchaseOrder({
      variables: {
        description,
        estimated_amount: parseFloat(estimatedAmount),
        station_id: parseInt(station),
        truck_id: parseInt(truck),
        vendor_id: parseInt(vendor),
        user_id: await getUserId(),
      },
    });

    if (imageId) {
      await addPurchaseOrderImage({
        variables: {
          purchase_order_id: result.data.createPurchaseOrder.id,
          user_id: getUserId(),
          image_id: imageId,
        },
      });
    }

    try {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      let description = 'There was a problem adding this Purchase Order';

      return <Error description={description} />;
    }
  }

  async function handleApprovePurchaseOrder(id) {
    await updatePurchaseOrder({
      variables: {
        id: parseInt(id),
        status_id: 3,
      },
    });

    try {
      setTimeout(() => {
        history.push('/purchaseorders');
      }, config.saveDialogTimeout);
    } catch (err) {
      let description = 'There was a problem updating this Purchase Order';

      return <Error description={description} />;
    }
  }

  async function handleClosePurchaseOrder(id) {
    await updatePurchaseOrder({
      variables: {
        id: parseInt(id),
        status_id: 5,
      },
    });

    try {
      setTimeout(() => {
        history.push('/purchaseorders');
      }, config.saveDialogTimeout);
    } catch (err) {
      let description = 'There was a problem updating this Purchase Order';

      return <Error description={description} />;
    }
  }

  async function handleDeclinePurchaseOrder(id) {
    await updatePurchaseOrder({
      variables: {
        id: parseInt(id),
        status_id: 4,
      },
    });

    try {
      setTimeout(() => {
        history.push('/purchaseorders');
      }, config.saveDialogTimeout);
    } catch (err) {
      let description = 'There was a problem updating this Purchase Order';

      return <Error description={description} />;
    }
  }

  async function handleCreateVendor(name) {
    console.log(`Creating Vendor ${name}`);

    await createVendor({
      variables: {
        name,
      },
    });
  }

  if (createVendorError || createPurchaseOrderError) {
    let description = 'There was a problem adding this Vendor';

    return <Error description={description} />;
  }

  let initialValues = {
    date: DateTime.now().toFormat('yyyy-MM-dd'),
  };

  let isReadyToAdd =
    props.isAdding &&
    !trucksLoading &&
    !stationsLoading &&
    !vendorsLoading &&
    !statusesLoading;

  let isReadyToEdit =
    !props.isAdding &&
    !trucksLoading &&
    !stationsLoading &&
    !vendorsLoading &&
    !statusesLoading &&
    !purchaseOrder.loading &&
    purchaseOrder.called;

  return (
    <>
      {isReadyToAdd && (
        <PurchaseOrderForm
          isAdding={props.isAdding}
          trucks={trucksData.getTrucks}
          stations={stationsData.getStations}
          vendors={vendorsData.getVendors}
          statuses={statusesData.getStatuses}
          handleSubmit={handleAddPurchaseOrder}
          handleCreateVendor={handleCreateVendor}
          initialValues={initialValues}
        />
      )}

      {isReadyToEdit && (
        <PurchaseOrderForm
          purchaseOrderId={id}
          isAdding={props.isAdding}
          trucks={trucksData.getTrucks}
          stations={stationsData.getStations}
          vendors={vendorsData.getVendors}
          statuses={statusesData.getStatuses}
          handleSubmit={handleUpdatePurchaseOrder}
          handleDelete={handleDeletePurchaseOrder}
          handleCreateVendor={handleCreateVendor}
          handleApprovePurchaseOrder={handleApprovePurchaseOrder}
          handleClosePurchaseOrder={handleClosePurchaseOrder}
          handleDeclinePurchaseOrder={handleDeclinePurchaseOrder}
          initialValues={purchaseOrder.data.getPurchaseOrder}
        />
      )}
    </>
  );
}
