import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import { AlertTriangleIcon, PlusIcon } from '../../components/Icons';
import Error from '../../components/Error';

import 'react-toastify/dist/ReactToastify.css';

const GET_FUEL_LOCATION_FILTERS = gql`
  query GetFuelLocationFilters {
    fuelLocationFilters {
      id
      siteNumber
      abbreviation
      location
      visible
    }
  }
`;

const UPDATE_FUEL_LOCATION_FILTER = gql`
  mutation UpdateFuelLocationFilter($locationId: Int!, $visible: Boolean!) {
    updateFuelLocationFilter(locationId: $locationId, visible: $visible) {
      id
      siteNumber
      abbreviation
      location
      visible
    }
  }
`;

export default function FuelLocations() {
  const fuelLocationFilters = useQuery(GET_FUEL_LOCATION_FILTERS, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'apisvc' },
  });

  const [filters, setFilters] = useState([]);
  const [updateFilter] = useMutation(UPDATE_FUEL_LOCATION_FILTER, {
    context: { clientName: 'apisvc' },
    refetchQueries: [
      { query: GET_FUEL_LOCATION_FILTERS, context: { clientName: 'apisvc' } },
    ],
  });

  const { isTokenExpired, deleteToken, hasPermission } = useToken();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (fuelLocationFilters.data) {
      var sortedFilters = fuelLocationFilters.data.fuelLocationFilters.sort(
        (a, b) => {
          return a.siteNumber - b.siteNumber;
        }
      );

      setFilters(sortedFilters);
    }
  }, [fuelLocationFilters.data]);

  if (!hasPermission('isadmin')) {
    return (
      <Error
        name="Permission Error"
        description="You do not have permission to view this page."
      />
    );
  }

  function handleVisibilityToggle(e, row) {
    let locationId = parseInt(row.id);
    let location = row.location;

    let invertedFilterVisibility = !row.visible;

    updateFilter({
      variables: {
        locationId,
        visible: invertedFilterVisibility,
      },
    }).then(() => {
      let filterVisibilityVerb = invertedFilterVisibility
        ? 'Visible'
        : 'Hidden';

      toast(`${location} is now ${filterVisibilityVerb}`);

      const newFilters = filters.map((f) => {
        if (f.id === locationId) {
          return { ...f, visible: invertedFilterVisibility };
        }

        return f;
      });

      setFilters(newFilters);
    });
  }

  const columns = [
    { name: 'Site Number', selector: (row) => row.siteNumber },
    { name: 'Abbreviation', selector: (row) => row.abbreviation },
    { name: 'Location', selector: (row) => row.location },
    {
      name: 'Visible',
      selector: (row) => row.visible,
      sortable: true,
      cell: (row, index, column, id) => {
        let visibleVerb = row.visible ? 'Visible' : 'Hidden';

        return (
          <label className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={row.visible}
              onChange={(e) => handleVisibilityToggle(e, row)}
            />
            <span className="form-check-label" style={{ fontSize: '.8em' }}>
              {visibleVerb}
            </span>
          </label>
        );
      },
    },
  ];

  let paginationPerPage = 50;

  return (
    <>
      <ToastContainer
        autoClose={2500}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />

      <h3>Update Locations</h3>
      <p>Use the button below to upload a new PDF.</p>
      <p className="bg-yellow" style={{ padding: '1em', borderRadius: '6px' }}>
        <span style={{ color: 'black' }}>
          <AlertTriangleIcon /> Please verify the <b>Fuel Locations</b> after
          each upload, as any change that FedEx makes to the PDF may result in a
          failure to properly read the PDF file!
        </span>
      </p>

      <Link
        to="/fuellocations/add"
        className="btn btn-outline-success btn-pill float-right"
        style={{ marginLeft: 'auto', marginBottom: '1rem' }}
      >
        <PlusIcon /> Upload PDF
      </Link>

      <h3>Manage Visible Locations</h3>
      <p>
        Use the <b>Visible</b> toggle in the table below to make each Site{' '}
        <em>Visible</em> or <em>Hidden</em>.
      </p>

      <div className="card mt-2 mb-1">
        <DataTable
          className="table card-table"
          data={filters}
          columns={columns}
          pagination
          striped
          paginationPerPage={paginationPerPage}
        />
      </div>
    </>
  );
}
